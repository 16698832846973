import { getJSONByUrl } from 'widgets/toolbox/ajax';

type TOneTrustCustomEvent = Event & {
    detail: string[]
}

export default {
    consentSetSessionUrl: '',
    GROUPS: {
        // the website needs these cookies in order to function properly (example: identify items placed into a shopping cart)
        strictlyNecessary: 'C0001',
        // get information about how site visitors are using the website (example: Google analytics)
        performance: 'C0002',
        // provide additional enhancement of the experience of site visitors (example: language selector for localization)
        functional: 'C0003',
        // cookies that attempt to gather more information about a user in order to personalize marketing (example: re-marketing)
        targeting: 'C0004',
        // social media services added to the site that enable users to share content with their friends and networks easily
        socialMedia: 'C0005'
    },

    /**
     * @description Initialize module
     */
    init() {
        window.addEventListener('OneTrustGroupsUpdated', (customEvent : Event) => {
            const oneTrustCustomEvent = <TOneTrustCustomEvent> customEvent;

            this.updateConsent(oneTrustCustomEvent.detail);
        });
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'consentSetSessionUrl' does not exist on type ... Remove this comment to see the full error message
        this.consentSetSessionUrl = window.sfccData?.consentSetSessionUrl || '';
    },

    /**
     * @description Handle consent groups updating
     * @param groups Accepted groups list
     */
    updateConsent: function (groups: string[]) {
        getJSONByUrl(this.consentSetSessionUrl, {
            consent: String(groups.indexOf(this.GROUPS.functional) > -1)
        });
    }
};
