import { TWidget } from 'widgets/Widget';
import { submitFormJson } from '../toolbox/ajax';
import { setCookie } from '../toolbox/cookie';

export default function (Widget: TWidget) {
    /**
     * @category widgets
     * @subcategory global
     * @class ConsentTracking
     * @augments Widget
     * @classdesc ConsentTracking Widget - used to expose Consent dialog, where customer can either accept
     * <br>or reject Consent matter. Relevant methods will be triggered pressing related buttons
     * @property {string} data-widget - Widget name `consentTracking`
     * @property {string} data-reject-url - Customer chooses `reject` - request will be sent to Reject URL
     * @property {string} data-accept-url - Customer chooses `accept` - request will be sent to Accept URL
     * @example <caption>Example of ConsentTracking widget usage</caption>
     * <div
     *     data-widget="consentTracking"
     *     data-reject-url="${URLUtils.url('ConsentTracking-SetSession', 'consent', 'false')}"
     *     data-accept-url="${URLUtils.url('ConsentTracking-SetSession', 'consent', 'true')}"
     *     data-tracking-selected="${pdict.tracking_consent != null}"
     *     data-consent-api="${pdict.caOnline}"
     * >
     *     <div class="b-notification_dialog">
     *         <div class="b-notification_dialog-inner">
     *             <div class="b-notification_dialog-content">Content message here</div>
     *             <button data-event-click="accept" type="button">${Resource.msg('tracking.accept', 'global', '')}</button>
     *             <button data-event-click="reject" type="button">${Resource.msg('tracking.decline', 'global', '')}</button>
     *         </div>
     *     </div>
     * </div>
     */
    class ConsentTracking extends Widget {
        prefs() {
            return {
                acceptUrl: '',
                rejectUrl: '',
                ...super.prefs()
            };
        }

        init(): void {
            this.ref('self').attr('role', 'alert');
        }

        /**
         * @description Toggle Panel visibility
         * @param isVisible - `true` if Consent's dialog should be shown, `false` - otherwise
         */
        togglePanel(isVisible: boolean): void {
            this.ref('self').toggle(isVisible);

            if (!isVisible) {
                this.eventBus().emit('highlighter.update');
            }
        }

        /**
         * @description Accept Consent handler
         * @listens dom#click
         * @returns Promise object represents server response for consent tracking accepting
         */
        accept(): Promise<Record<string, unknown>> {
            return submitFormJson(this.prefs().acceptUrl, undefined, 'GET').finally(() => {
                this.togglePanel(false);
                setCookie('dw_accept_cookie', '1', 365, true);
                setCookie('dw_consent_cookie', '1', 365, true);
            });
        }

        /**
         * @description Reject Consent handler
         * @listens dom#click
         * @returns Promise object represents server response for consent tracking rejection
         */
        reject(): Promise<Record<string, unknown>> {
            return submitFormJson(this.prefs().rejectUrl, undefined, 'GET').finally(() => {
                this.togglePanel(false);
                setCookie('dw_accept_cookie', '1', 365, true);
            });
        }
    }

    return ConsentTracking;
}
