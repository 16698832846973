import { getData, log } from './util';

const HIDDEN_CLASS = 'm-hide';
const REFELEMENT_ERROR = 'Reference element is not found';

/**
 * @description Convert string to snake-case
 * @param {string} s String in camelCase to convert
 * @returns {string} Converted to snake-case string
 * @example myCustomProperty => my-custom-property
 */
function toSnakeCase(s: string): string {
    return s.replace(/(?:^|\.?)([A-Z])/g, (x, y) => '-' + y.toLowerCase()).replace(/^_/, '');
}

/**
 * @category widgets
 * @subcategory toolbox
 * @class RefElement
 * @classdesc jQuery like wrapper for simple access to DOM. It will be added into `Widget.items` array of first parent widget.
 * Represents RefElement component with next features:
 * 1. Allow to get/set data attribute depends on provided/not provided value
 * 2. Allow to get/set the value into element
 * 3. Allow to append/prepend string into element
 * 4. Allow to get/set/remove attribute for element
 * 5. Allow to check that some element has attribute `attributeName`
 * 6. Allow to get/set property value for `propertyName` property for element
 * 7. Allow to get element of set by idx
 * 8. Allow to replace content of element by empty string
 * 9. Allow to remove element
 * 10. Allow to enable/disable element or check if element is disabled
 * 11. Allow to get/set the content `text` into element
 * 12. Allow to set focus on element
 * 13. Allow to show/hide element or toggle state
 * 14. Allow to add/remove/toggle or check if element has class
 * 15. Allow to specify the position of the element in the window
 * @example <caption>Example of RefElement widget usage</caption>
 * // to use just add data-ref attribute
 * <div data-ref="myRefElement"></div>
 *
 * // to get it in any widget method
 * this.ref('myRefElement')
 */
export class RefElement {
    /**
     * @description Array of HTMLElements that current RefElement represents
     */
    els: HTMLElement[];

    /**
     * @description Define array of elements
     * @param {HTMLElement[]} els array of elements
     */
    constructor(els: HTMLElement[]) {
        this.els = els;
    }

    /**
     * @description Get amount of elements in set
     * @returns Number of elements in set
     */
    get length(): number {
        return this.els.length;
    }

    /**
     * @description Checks if there are elements in set
     * @returns result
     */
    exists(): boolean {
        return this.length > 0;
    }

    /**
     * @description Get or Set data attribute depends on provided/not provided value
     * @param name Name of data attribute in camelCase, f.e. `testIt` to get `data-test-it`
     * @param [value] to set
     * @returns
     * - if value provided - returns current instance for chaining
     * - otherwise provided value of data attribute with appropriate type or undefined if attribute doesn't exist
     */
    data(name: string, value?: any): this | Record<string, unknown> | string | number | boolean | null | undefined {
        if (!PRODUCTION && !this.exists()) {
            log.warn(REFELEMENT_ERROR, this);
        }

        const attrName = 'data-' + toSnakeCase(name);

        if (typeof value === 'undefined') {
            if (this.hasAttr(attrName)) {
                const attrValue = this.attr(attrName);

                if (typeof attrValue === 'string') {
                    return getData(attrValue);
                }
            }

            return undefined;
        }

        return this.attr(attrName, value);
    }

    /**
     * @description Get or set the value into elements in set
     * @param [value] If not empty set value into inputs in set
     * @returns
     * - If value: undefined - returns joined string of values in set of inputs
     * - Otherwise returns current instance for chaining
     */
    val(value?: any): string | this {
        if (!PRODUCTION && !this.exists()) {
            log.warn(REFELEMENT_ERROR, this);
        }

        if (typeof value === 'undefined') {
            return this.els.map(el => (<HTMLInputElement>el).value).join('');
        }

        if (typeof value === 'string') {
            this.els.forEach(el => { (<HTMLInputElement>el).value = value; });
        }

        return this;
    }

    /**
     * @description Get validity object for first element in set
     * @returns
     * - If element instance of `HTMLInputElement|HTMLSelectElement` returns validity object
     * - Otherwise returns `undefined`
     */
    getValidity(): { state: ValidityState; msg: string; } | undefined {
        if (!PRODUCTION && !this.exists()) {
            log.warn(REFELEMENT_ERROR, this);
        }

        const element = this.els[0];

        if (element instanceof HTMLInputElement
            || element instanceof HTMLSelectElement
            || element instanceof HTMLTextAreaElement
        ) {
            return {
                state: element.validity,
                msg: element.validationMessage
            };
        }

        return undefined;
    }

    /**
     * @description Appends string into each element from set
     * @param content String to append
     */
    append(content: string): void {
        if (!PRODUCTION && !this.exists()) {
            log.warn(REFELEMENT_ERROR, this);
        }

        this.els.forEach(el => {
            const tempEl = document.createElement('div');

            tempEl.innerHTML = content;

            const scripts: NodeListOf<HTMLScriptElement> = tempEl.querySelectorAll(
                'script[type="text/javascript"]'
            );

            Array.from(scripts).forEach(script => {
                if (script && script.parentNode) {
                    script.parentNode.removeChild(script);
                }
            });

            Array.from(tempEl.childNodes).forEach(child => {
                el.appendChild(child);
            });

            Array.from(scripts).forEach((script) => {
                const tempScript = document.createElement('script');

                tempScript.text = script.text;

                el.appendChild(tempScript);
            });

            tempEl.innerHTML = '';
        });
    }

    /**
     * @description Prepends string into each element from set
     * @param content String to prepend
     */
    prepend(content: string): void {
        if (!PRODUCTION && !this.exists()) {
            log.warn(REFELEMENT_ERROR, this);
        }

        this.els.forEach(el => {
            const tempEl = document.createElement('div');

            tempEl.innerHTML = content;

            const scripts: NodeListOf<HTMLScriptElement> = tempEl.querySelectorAll(
                'script[type="text/javascript"]'
            );

            Array.from(scripts).forEach(script => {
                if (script && script.parentNode) {
                    script.parentNode.removeChild(script);
                }
            });

            Array.from(tempEl.childNodes).reverse().forEach(child => el.prepend(child));

            Array.from(scripts).forEach((script) => {
                const tempScript = document.createElement('script');

                tempScript.text = script.text;

                el.appendChild(tempScript);
            });

            tempEl.innerHTML = '';
        });
    }

    /**
     * @description
     * Get/Set/Remove attribute for each element of set depends on params:
     * - if value: undefined - Get attribute value
     * - if value: true - Set attribute attribute="attribute", f.e. `attr('disabled', true)` => `disabled="disabled"`
     * - if value: null|false - Remove attribute if `value`
     * - any another type - Convert value to string and set as attributeValue
     * @param attributeName Name of attribute
     * @param [value] to set (null or false to remove attribute)
     * @returns
     * - If value: undefined - Returns string with joined values from attribute
     * - Otherwise returns current instance for chaining
     */
    attr(attributeName: string, value?: any): string | this {
        if (!PRODUCTION && !this.exists()) {
            log.warn(REFELEMENT_ERROR, this);
        }

        if (value === false || value === null) {
            this.els.forEach(el => el.removeAttribute(attributeName));
        } else if (value === true) {
            this.els.forEach(el => el.setAttribute(attributeName, attributeName));
        } else if (value !== undefined) {
            this.els.forEach(el => el.setAttribute(attributeName, value));
        } else {
            return this.els.map(el => el.getAttribute(attributeName)).join('');
        }

        return this;
    }

    /**
     * @description Check that some element in the set of elements has attribute `attributeName`
     * @param attributeName name of attribute
     * @returns `true` if has such attribute
     */
    hasAttr(attributeName: string): boolean {
        if (!PRODUCTION && !this.exists()) {
            log.warn(REFELEMENT_ERROR, this);
        }

        return this.els.some(el => el.hasAttribute(attributeName));
    }

    /**
     * @description
     * - Get property value by `propertyName` from first element in set if `value` parameter is not provided
     * - Set property value for `propertyName` property for each element in set
     * @param propertyName The name of the property to get or set.
     * @param [value] A value to set for the property.
     * @returns Returns undefined for the value of a property that has not been set
     * or property value if exists
     */
    prop(propertyName: keyof HTMLInputElement, value?: string | boolean | undefined): any {
        if (!PRODUCTION && !this.exists()) {
            log.warn(REFELEMENT_ERROR, this);
        }

        if (typeof value === 'undefined') {
            const el: HTMLInputElement = <HTMLInputElement> this.els[0];

            return el[propertyName];
        }

        // @ts-expect-error ts-migrate(2540) FIXME: Cannot assign to 'ATTRIBUTE_NODE' because it is a ... Remove this comment to see the full error message
        this.els.forEach(el => { el[propertyName] = value; });

        return undefined;
    }

    /**
     * @description Get element of set by idx
     * @param [idx] Identifier of element, first by default
     * @returns element if founded
     */
    get(idx = 0): HTMLElement | undefined {
        if (!PRODUCTION && !this.exists()) {
            log.warn(REFELEMENT_ERROR, this);
        }

        if (this.els[idx]) {
            return this.els[idx];
        }

        return undefined;
    }

    /**
     * @description Replace content of each element in set by empty string
     * @returns current instance for chaining
     */
    empty(): this {
        if (!PRODUCTION && !this.exists()) {
            log.warn(REFELEMENT_ERROR, this);
        }

        this.els.forEach(el => { el.textContent = ''; });

        return this;
    }

    /**
     * @description Remove set of elements
     * @returns current instance for chaining
     */
    remove(): this {
        if (!PRODUCTION && !this.exists()) {
            log.warn(REFELEMENT_ERROR, this);
        }

        this.els.forEach(el => el.parentNode && el.parentNode.removeChild(el));

        return this;
    }

    /**
     * @description Add attribute disabled="disabled"
     * @returns current instance for chaining
     */
    disable(): this {
        if (!PRODUCTION && !this.exists()) {
            log.warn(REFELEMENT_ERROR, this);
        }

        this.attr('disabled', true);
        this.addClass('m-disabled');

        return this;
    }

    /**
     * @description Remove attribute disabled="disabled"
     * @returns current instance for chaining
     */
    enable(): this {
        if (!PRODUCTION && !this.exists()) {
            log.warn(REFELEMENT_ERROR, this);
        }

        this.removeClass('m-disabled');
        this.attr('disabled', false);

        return this;
    }

    /**
     * @description Check if every element in set has disabled attribute
     * @returns true if disabled
     */
    isDisabled(): boolean {
        if (!PRODUCTION && !this.exists()) {
            log.warn(REFELEMENT_ERROR, this);
        }

        return this.attr('disabled') === 'disabled';
    }

    /**
     * @description Set the content `text` into each element in the set
     * @param text The text to place as content
     * @returns current instance for chaining
     */
    setText(text: string): this {
        if (!PRODUCTION && !this.exists()) {
            log.warn(REFELEMENT_ERROR, this);
        }

        this.els.forEach(el => {
            if (el.textContent !== text) {
                el.textContent = text;
            }
        });

        return this;
    }

    /**
     * @description Get the content of each element from set and join it to string
     * @returns Joined text from set of elements
     */
    getText(): string {
        if (!PRODUCTION && !this.exists()) {
            log.warn(REFELEMENT_ERROR, this);
        }

        return this.els.map(el => el.textContent).join();
    }

    /**
     * @description Focus first element
     */
    focus(): void {
        if (!PRODUCTION && !this.exists()) {
            log.warn(REFELEMENT_ERROR, this);
        }

        if (this.els[0]) {
            this.els[0].focus();
        }
    }

    /**
     * @description Hide element
     * @returns current instance for chaining
     */
    hide(): this {
        if (!PRODUCTION && !this.exists()) {
            log.warn(REFELEMENT_ERROR, this);
        }

        if (!this.hasClass(HIDDEN_CLASS)) {
            this.attr('hidden', true);
            this.addClass(HIDDEN_CLASS);
        }

        return this;
    }

    /**
     * @description Show element
     * @returns current instance for chaining
     */
    show(): this {
        if (!PRODUCTION && !this.exists()) {
            log.warn(REFELEMENT_ERROR, this);
        }

        this.attr('hidden', false);
        this.removeClass(HIDDEN_CLASS);

        return this;
    }

    /**
     * @description Disable or enable element depending on passed state
     * @param state Based on passed state element will be enabled or disable
     * @returns current instance for chaining
     */
    toggleAvailability(state: boolean): this {
        if (!PRODUCTION && !this.exists()) {
            log.warn(REFELEMENT_ERROR, this);
        }

        this[state ? 'enable' : 'disable']();

        return this;
    }

    /**
     * @description Show or hide element depending on either the presence or `initialState` parameter
     * @param [initialState]  true - show else false hide
     * @returns current instance for chaining
     */
    toggle(initialState?: boolean): this {
        if (!PRODUCTION && !this.exists()) {
            log.warn(REFELEMENT_ERROR, this);
        }

        this[initialState ? 'show' : 'hide']();

        return this;
    }

    /**
     * @description Add or Remove class depending on either the class's presence or the `state` parameter
     * @param className name of class
     * @param [state] true to add, false to remove class
     * @returns current instance for chaining
     */
    toggleClass(className: string, state?: boolean): this {
        if (!PRODUCTION && !this.exists()) {
            log.warn(REFELEMENT_ERROR, this);
        }

        if (state === undefined) {
            if (this.hasClass(className)) {
                this.removeClass(className);
            } else {
                this.addClass(className);
            }
        } else if (state) {
            this.addClass(className);
        } else {
            this.removeClass(className);
        }

        return this;
    }

    /**
     * @description Add single class or multiple classes into element
     * @param classNames string or strings array of class name(s)
     * @returns current instance for chaining
     */
    addClass(classNames: string | string[]): this {
        if (!PRODUCTION && !this.exists()) {
            log.warn(REFELEMENT_ERROR, this);
        }

        if (typeof classNames === 'string') {
            classNames = classNames.split(' ');
        }

        classNames.forEach(className => {
            this.els.forEach(el => {
                if (!this.hasClass(className)) {
                    el.classList.add(className);
                }
            });
        });

        return this;
    }

    /**
     * @description Remove single class or multiple classes for element in set
     * @param classNames array or string of class names
     * @returns current instance for chaining
     */
    removeClass(classNames: string | string[]): this {
        if (!PRODUCTION && !this.exists()) {
            log.warn(REFELEMENT_ERROR, this);
        }

        if (typeof classNames === 'string') {
            classNames = classNames.split(' ');
        }

        classNames.forEach(className => {
            this.els.forEach(el => {
                if (this.hasClass(className)) {
                    el.classList.remove(className);
                }
            });
        });

        return this;
    }

    /**
     * @description Determine whether each element in set have assigned the given class
     * @param className The class name to search for.
     * @returns `true` if yes
     */
    hasClass(className: string): boolean {
        if (!PRODUCTION && !this.exists()) {
            log.warn(REFELEMENT_ERROR, this);
        }

        return this.els.every(el => el.classList.contains(className));
    }

    /**
     * @description Specifies the position of the element in the window
     * @returns object with top and left position in px
     */
    offset(): { top: number; left: number; } {
        if (!PRODUCTION && !this.exists()) {
            log.warn(REFELEMENT_ERROR, this);
        }

        const ret = { top: 0, left: 0 };

        if (this.els.length) {
            const docElem = document.documentElement;
            const elemBox = this.els[0].getBoundingClientRect();

            ret.top = elemBox.top + window.pageYOffset - docElem.clientTop;
            ret.left = elemBox.left + window.pageXOffset - docElem.clientLeft;
        }

        return ret;
    }
}

export type IRefElement = InstanceType<typeof RefElement>;
