/**
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} RefElement
 */

/**
 * @description Base InputPassword implementation
 * @param BasicInput Base widget for extending
 * @returns Input Password class
 */
export default function (BasicInput: ReturnType<typeof import('widgets/forms/BasicInput').default>) {
    /**
     * @category widgets
     * @subcategory forms
     * @class InputPassword
     * @augments BasicInput
     * @classdesc Specific Password input, contains specific password validation logic. Represents input `password` element together with widget-related HTML markup.
     * HTML structure assembled on backend and injected into resulted html markup by using `formElement` component
     * and dynamic forms configuration JSON.
     * Uses 'show/hide mask' functionality, which allows customer to see/hide printed password characters.
     * Adds additional validation constraints rules, which are password-specific (like special characters, uppercased characters etc).
     * @property {string} data-widget - Widget name `inputPassword`
     * @property {boolean} data-password-strength-enabled - Is strength enabled for this password field
     * @example <caption>InputPassword definition in dynamicforms.json</caption>
     * ...
     * // fields -> input -> password
     * password: {
     *     extends: 'fields.input.base',
     *     'caption.show': true,
     *     'caption.text': 'form.profile.password.caption',
     *     'label.text': 'form.profile.password',
     *     element: {
     *         type: 'password',
     *         required: true,
     *         minLength: 8,
     *         maxLength: 64,
     *         attributes: {
     *             'data-event-input': 'onPasswordInput',
     *             'data-event-focus': 'handleFocus'
     *         }
     *     }
     * },
     * ...
     * @example <caption>Insertion of InputPassword inside ISML templates</caption>
     * <isset name="formElement" value="${require('forms/formElement')}" scope="page"/>
     * ...
     * <form>
     *     ...
     *     <isprint value="${
     *         formElement(pdict.profile.password).render()
     *     }" encoding="off"/>
     *     ...
     * </form>
     * @example <caption>Resulted HTML structure for InputPassword</caption>
     * <div data-widget="inputPassword" class="b-form_section m-required m-invalid"
     *     data-id="dwfrm_login_password" data-validation-config="... validation cinfig"
     * >
     *     <label class="b-form_section-label" for="dwfrm_login_password">
     *         <span class="b-form_section-required" aria-hidden="true">*</span>
     *         Password
     *     </label>
     *     <div class="b-input_password">
     *         <input data-ref="field" id="dwfrm_login_password" type="password" class="b-input m-invalid"
     *            aria-describedby="dwfrm_login_password-error" placeholder="" name="dwfrm_login_password" required="" value=""
     *            maxlength="64" aria-required="true" minlength="8" data-event-input="onPasswordInput"
     *            autocomplete="current-password" data-tau="login_password" data-event-blur="validate"
     *         >
     *         <button class="b-input_password-toggle_visibility" data-ref="showButton" data-event-click="toggleMask"
     *             data-button-text-show="Show" data-button-text-hide="Hide" aria-pressed="false"
     *             title="Toggle password field visibility" type="button" hidden="hidden">Show</button>
     *     </div>
     *     <div role="alert" class="b-form_section-message" data-ref="errorFeedback" id="dwfrm_login_password-error">This field is required.</div>
     * </div>
     */
    class InputPassword extends BasicInput {
        prefs() {
            return {
                upperCaseAmount: 0,
                lowerCaseAmount: 0,
                numbersAmount: 0,
                specialCharsAmount: 0,
                specialCharsList: '$%/()[]{}=?!.,-_*|+~#',
                maxWeakPasswordScore: 1,
                maxMediumPasswordScore: 2,
                maxStrongPasswordScore: 4,
                classesWeakPassword: 'm-weak',
                classesMediumPassword: 'm-medium',
                classesStrongPassword: 'm-strong',
                passwordStrengthEnabled: false,
                ...super.prefs()
            };
        }

        init() {
            super.init();

            if (this.prefs().passwordStrengthEnabled) {
                import(/* webpackChunkName: 'zxcvbn' */'zxcvbn')
                    .then((zxcvbn) => {
                        // @ts-expect-error ts-migrate(2339) FIXME: Property 'passwordEstimator' does not exist on typ... Remove this comment to see the full error message
                        this.passwordEstimator = zxcvbn.default;
                    });
            }
        }

        /**
         * @description Checks if entered data is number
         * @param {string} val - entered password
         * @returns {boolean} check result
         */
        isNumber(val) {
            return '0123456789'.includes(val);
        }

        /**
         * @description Checks if entered data is simple character (not a number or special character)
         * @param {string} val - entered password
         * @returns {boolean} check result
         */
        isSimpleChar(val) {
            return !this.isNumber(val) && !this.prefs().specialCharsList.includes(val);
        }

        /**
         * @description Verifies if entered password is valid in case of using password strength estimation instead of
         * default password validation
         * @returns {boolean} check result
         */
        isStrongPassword() {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'passwordStrengthScore' does not exist on... Remove this comment to see the full error message
            if (this.passwordStrengthScore > this.prefs().maxMediumPasswordScore) {
                return true;
            } else {
                this.error = <string> this.ref('passwordEstimator').data('errorWeakPassword');

                return false;
            }
        }

        /**
         * @description Verifies if entered password is valid and meets all password constraints
         * @returns {boolean} check result
         */
        isValid() {
            const val = this.getValue();

            if (!super.isValid()) {
                // Show password strength error instead of default input errors except for empty field state
                if (!this.prefs().passwordStrengthEnabled || !val.length) {
                    return false;
                }
            }

            if (typeof val === 'string' && val) {
                if (this.widgetsToMatch && this.widgetsToMatch.length) {
                    const invalidWidget = this.getInvalidCompareWithWidget();

                    if (invalidWidget) {
                        this.error = invalidWidget.widgetToMatchOpts.msg || '';

                        return false;
                    }
                }

                return this.validatePasswordChars(val);
            }

            return true;
        }

        /**
         * @description Verifies if entered password meets the strength requirements
         * @param {string} val - entered password
         * @returns {boolean} check result
         */
        validatePasswordChars(val) {
            const valChars = val.split('');

            if (this.prefs().passwordStrengthEnabled) {
                return this.isStrongPassword();
            } else if (
                this.checkLowerCaseAmount(valChars)
                && this.checkUpperCaseAmount(valChars)
                && this.checkNumberAmount(valChars)
                && this.checkSpecialCharsAmount(valChars)
            ) {
                return true;
            }

            return false;
        }

        /**
         * @description Checks special characters amount in entered password as per corresponding constraint.
         * @param {string[]} valChars - array of entered password symbols
         * @returns {boolean} check results
         */
        checkSpecialCharsAmount(valChars) {
            if (this.prefs().specialCharsAmount) {
                const specialCharsList = this.prefs().specialCharsList;
                const specialCharsLetters = valChars.filter(char => specialCharsList.includes(char));

                if (specialCharsLetters.length < this.prefs().specialCharsAmount) {
                    this.error = this.prefs().validationConfig.errors.specialCharsAmount;

                    return false;
                }
            }

            return true;
        }

        /**
         * @description Checks numbers amount in entered password as per corresponding constraint.
         * @param {string[]} valChars - array of entered password symbols
         * @returns {boolean} check result
         */
        checkNumberAmount(valChars) {
            if (this.prefs().numbersAmount) {
                const numberLetters = valChars.filter(char => this.isNumber(char));

                if (numberLetters.length < this.prefs().numbersAmount) {
                    this.error = this.prefs().validationConfig.errors.numbersAmount;

                    return false;
                }
            }

            return true;
        }

        /**
         * @description Checks lowercase characters amount in entered password as per corresponding constraint.
         * @param {string[]} valChars - array of entered password symbols
         * @returns {boolean} check result
         */
        checkLowerCaseAmount(valChars) {
            if (this.prefs().lowerCaseAmount) {
                const lowerCaseLetters = valChars.filter(char => char === char.toLowerCase() && char.toUpperCase() !== char.toLowerCase());

                if (lowerCaseLetters.length < this.prefs().lowerCaseAmount) {
                    this.error = this.prefs().validationConfig.errors.lowerCaseAmount;

                    return false;
                }
            }

            return true;
        }

        /**
         * @description Checks uppercase characters amount in entered password as per corresponding constraint.
         * @param {string[]} valChars - array of entered password symbols
         * @returns {boolean} check results
         */
        checkUpperCaseAmount(valChars) {
            if (this.prefs().upperCaseAmount) {
                const upperCaseLetters = valChars.filter(char => char === char.toUpperCase() && char.toUpperCase() !== char.toLowerCase());

                if (upperCaseLetters.length < this.prefs().upperCaseAmount) {
                    this.error = this.prefs().validationConfig.errors.upperCaseAmount;

                    return false;
                }
            }

            return true;
        }

        /**
         * @description Show mask button inside password field
         * @returns {void}
         */
        showMaskButton() {
            this.ref('showButton').show();
        }

        /**
         * @description Handle click on button Show/Hide mask. Toggle type based on input type.
         * @listens dom#click
         * @returns {void}
         */
        toggleMask() {
            const input = this.ref('field');
            const inputType = input.attr('type');
            const showButton = this.ref('showButton');
            const showText = showButton.data('buttonTextShow');
            const hideText = showButton.data('buttonTextHide');

            if (inputType === 'password') {
                input.attr('type', 'text');
                showButton.attr('aria-pressed', 'true');

                // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | number | boolean | RefE... Remove this comment to see the full error message
                showButton.setText(hideText);
            } else {
                input.attr('type', 'password');
                showButton.attr('aria-pressed', 'false');

                // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | number | boolean | RefE... Remove this comment to see the full error message
                showButton.setText(showText);
            }
        }

        /**
         * @description Listener for `input` event.
         * Usually used to display Show/Hide mask button when entered needed amount of symbols into password field.
         * @listens dom#input
         * @param {RefElement} el event source element
         * @returns {void}
         */
        onPasswordInput(el) {
            const entered = el && el.val();

            if (entered && entered.length) {
                this.showMaskButton();
            }

            if (this.prefs().passwordStrengthEnabled) {
                this.showPasswordEstimator(el);
            }
        }

        /**
         * @description Generates an array of indicator item classes with different filling color
         * @returns {Array} Indicator item classes
         */
        generateIndicatorItems() {
            let indicatorClass;

            // Defined class name to set appropriate indicator items fill color

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'passwordStrengthScore' does not exist on... Remove this comment to see the full error message
            if (this.passwordStrengthScore === null) {
                indicatorClass = '';
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'passwordStrengthScore' does not exist on... Remove this comment to see the full error message
            } else if (this.passwordStrengthScore <= this.prefs().maxWeakPasswordScore) {
                indicatorClass = this.prefs().classesWeakPassword;
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'passwordStrengthScore' does not exist on... Remove this comment to see the full error message
            } else if (this.passwordStrengthScore <= this.prefs().maxMediumPasswordScore) {
                indicatorClass = this.prefs().classesMediumPassword;
            } else {
                indicatorClass = this.prefs().classesStrongPassword;
            }

            const indicatorItems = [];

            for (let i = 0; i <= this.prefs().maxStrongPasswordScore; i++) {
                indicatorItems[i] = {

                    // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
                    className: (this.passwordStrengthScore !== null && i <= this.passwordStrengthScore) ? indicatorClass : ''
                };
            }

            return indicatorItems;
        }

        /**
         * @description Show password strength estimator block
         * @param {RefElement} el - field source element
         * @returns {Promise<void|null>} Promise object represents password estimator rendering result
         */
        showPasswordEstimator(el) {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'passwordEstimator' does not exist on typ... Remove this comment to see the full error message
            if (!this.passwordEstimator) {
                return Promise.resolve(null);
            }

            const value = el && el.val();

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'passwordStrengthScore' does not exist on... Remove this comment to see the full error message
            this.passwordStrengthScore = value.length ? this.passwordEstimator(value).score : null;
            const passwordIndicatorItems = this.generateIndicatorItems();
            const passwordStrengthDescriptions = this.ref('passwordEstimator').data('passwordDescriptions');
            const passwordStrengthDescription = (passwordStrengthDescriptions

                // @ts-expect-error ts-migrate(2339) FIXME: Property 'passwordStrengthScore' does not exist on... Remove this comment to see the full error message
                && passwordStrengthDescriptions[this.passwordStrengthScore]) || '';

            // Show updated password strength indicator and description
            return this.render(
                'passwordEstimatorTemplate',
                { passwordIndicatorItems, passwordStrengthDescription },
                this.ref('passwordEstimatorIndicator')
            ).then(() => {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'passwordStrengthScore' does not exist on... Remove this comment to see the full error message
                if (this.passwordStrengthScore === this.prefs().maxStrongPasswordScore) {
                    this.ref('passwordEstimatorCaption').remove();
                }

                this.ref('passwordEstimator').show();
            });
        }

        /**
         * @description Handles `focus` on password input with strength estimation
         * @listens dom#focus
         * @param {RefElement} el event source element
         * @returns {void}
         */
        handleFocus(el) {
            this.showPasswordEstimator(el);
        }
    }

    return InputPassword;
}
