// @ts-nocheck
import WidgetsMgr from 'widgets/widgetsMgr';
import EmailSubscribeExt from './forms/ajax/EmailSubscribeExt';
import inputSelectExt from './forms/InputSelectExt';
import oneTrust from './toolbox/oneTrust';
import EmitBusEventOrderCancel from 'widgets/global/EmitBusEventOrderCancel';
import PreHeaderScript from 'core/header/PreHeaderScript';

oneTrust.init();

WidgetsMgr.addWidgetsList('global.core', () => [
    ['emailSubscribe', EmailSubscribeExt, 'emailSubscribe'],
    ['inputSelect', inputSelectExt, 'inputSelect'],
    ['emitBusEventOrderCancel', EmitBusEventOrderCancel],
    ['preHeaderScript', PreHeaderScript]
]);

const hasContext = (context) => window.contexts.includes(context);

WidgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('account'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'account.adyen' */ './forms/AddressBookFormExt').then(d => d.default),
        import(/* webpackChunkName: 'account.adyen' */ './account/PaymentsListExt').then(d => d.default),
        import(/* webpackChunkName: 'account.adyen' */ './account/SignUp').then(d => d.default)
    ]).then(deps => {
        const [
            AddressBookFormExt,
            PaymentsListExt,
            SignUp
        ] = deps;

        return {
            listId: 'account.payment.adyen.core',
            widgetsDefinition: () => [
                ['addressBookForm', AddressBookFormExt, 'addressBookForm'],
                ['paymentsList', PaymentsListExt, 'paymentsList'],
                ['signUp', SignUp, 'ajaxform']

            ]
        };
    })
});

WidgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('account'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'product.widgets' */ 'widgets/forms/ajax/TrackForm').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'widgets/account/OrderCancel').then(d => d.default)
    ]).then(deps => {
        const [
            TrackForm,
            OrderCancel
        ] = deps;

        return {
            listId: 'account.widgets.custom.core',
            widgetsDefinition: () => [
                ['trackForm', TrackForm, 'ajaxform'],
                ['orderCancel', OrderCancel]
            ]
        };
    })
});

WidgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('pdp'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'pdp.core.widgets' */ 'widgets/content/ContactUs').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */'core/product/QuickView').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */'core/global/DisclosureExt').then(d => d.default)
    ]).then(deps => {
        const [ContactUs, QuickView, DisclosureExt] = deps;

        return {
            listId: 'pdp.core',
            widgetsDefinition: () => [
                ['contactUs', ContactUs],
                ['productDetail', DisclosureExt, 'productDetail'],
                ['quickView', QuickView, 'productDetail']
            ]
        };
    })
});

WidgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('plp'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'search.widgets' */ './search/ProductListingMgrExt').then(d => d.default)
    ]).then(deps => {
        const [
            ProductListingMgrExt
        ] = deps;

        return {
            listId: 'search.widgets.core',
            widgetsDefinition: () => [
                ['productListingMgr', ProductListingMgrExt, 'productListingMgr']
            ]
        };
    })
});

WidgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('header'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'header.widgets' */'core/header/CountrySelectorDialog').then(d => d.default),
        import(/* webpackChunkName: 'header.widgets' */'core/header/CountrySelectHamburgerMenuItem').then(d => d.default),
        import(/* webpackChunkName: 'header.widgets' */'core/header/CountrySelectorForm').then(d => d.default),
        import(/* webpackChunkName: 'header.widgets' */'core/header/SwitchCountryPopup').then(d => d.default)
    ]).then(deps => {
        const [
            CountrySelectorDialog,
            CountrySelectHamburgerMenuItem,
            CountrySelectorForm,
            SwitchCountryPopup
        ] = deps;

        return {
            listId: 'header.core',
            widgetsDefinition: () => [
                // modal
                ['countrySelectorHamburgerMenuItem', CountrySelectHamburgerMenuItem, 'hamburgerMenuItem'],
                ['countrySelectorDialog', CountrySelectorDialog, 'modal'],
                ['switchCountryPopup', SwitchCountryPopup, 'modal'],
                ['countrySelectorForm', CountrySelectorForm, 'ajaxform']
            ]
        };
    })
});

WidgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('home'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'homepage.widgets' */ 'core/global/VideoPlaceholder').then(d => d.default),
        import(/* webpackChunkName: 'homepage.widgets' */ 'core/global/VideoCustomControls').then(d => d.default)
    ]).then(deps => {
        const [VideoPlaceholder,
            VideoCustomControls] = deps;

        return {
            listId: 'homepage.widgets.core',
            widgetsDefinition: () => [
                ['videoPlaceholder', VideoPlaceholder],
                ['videoCustomControls', VideoCustomControls]
            ]
        };
    })
});

WidgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('checkout'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'checkout.widgets' */ 'core/checkout/CoreSummaryStepMixin').then(d => d.default),
        import(/* webpackChunkName: 'checkout.adyen' */ './checkout/AdyenPaymentAccordionExt').then(d => d.default),
        import(/* webpackChunkName: 'checkout.adyen' */ './checkout/ShippingFormExt').then(d => d.default),
        import(/* webpackChunkName: 'checkout.adyen' */ './checkout/ShippingStepMixinExt').then(d => d.default),
        import(/* webpackChunkName: 'checkout.adyen' */ './checkout/PaymentAccordionItemAdyenComponent').then(d => d.default),
        import(/* webpackChunkName: 'checkout.adyen' */ './checkout/PayPalComponentMixin').then(d => d.default),
        import(/* webpackChunkName: 'checkout.adyen' */ './checkout/PayPalExpressReviewMgr').then(d => d.default)
    ]).then(deps => {
        const [
            CoreSummaryStepMixin,
            AdyenPaymentAccordionExt,
            ShippingFormExt,
            ShippingStepMixinExt,
            PaymentAccordionItemAdyenComponent,
            PayPalComponentMixin,
            PayPalExpressReviewMgr
        ] = deps;

        return {
            listId: 'checkout.widgets.core',
            widgetsDefinition: () => [
                ['checkoutMgr', CoreSummaryStepMixin, 'checkoutMgr'],
                ['paymentAccordion', AdyenPaymentAccordionExt, 'paymentAccordion'],
                ['shippingForm', ShippingFormExt, 'shippingForm'],
                ['checkoutMgr', ShippingStepMixinExt, 'checkoutMgr'],
                ['paymentAccordionItemAdyenComponent', PaymentAccordionItemAdyenComponent, 'paymentAccordionItem'],
                ['paymentAccordionItemAdyenComponent', PayPalComponentMixin, 'paymentAccordionItemAdyenComponent'],
                ['payPalExpressReviewMgr', PayPalExpressReviewMgr]
            ]
        };
    })
});

WidgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('orderconfirmation'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'account.adyen' */ './account/SignUp').then(d => d.default)
    ]).then(deps => {
        const [SignUp] = deps;

        return {
            listId: 'content.widgets',
            widgetsDefinition: () => [
                ['signUp', SignUp, 'ajaxform']
            ]
        };
    })
});

WidgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('wishlist'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'wishlist.wishlist' */ 'core/wishlist/WishlistMgrMixin').then(d => d.default)
    ]).then(deps => {
        const [WishlistMgrMixin] = deps;

        return {
            listId: 'wishlist.core',
            widgetsDefinition: () => [
                ['wishlistMgr', WishlistMgrMixin, 'wishlistMgr']
            ]
        };
    })
});

WidgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('cart'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'cart.adyen' */ 'core/cart/PayPalExpress').then(d => d.default),
        import(/* webpackChunkName: 'cart.core' */ 'core/cart/PayPalExpressExt').then(d => d.default),
        import(/* webpackChunkName: 'cart.core' */ 'core/cart/CartMgrExt').then(d => d.default),
        import(/* webpackChunkName: 'cart.core' */ 'core/forms/CouponFormExt').then(d => d.default),
        import(/* webpackChunkName: 'cart.adyen' */ 'core/cart/CartMgrPayPalExpressMixin').then(d => d.default)
    ]).then(deps => {
        const [
            PayPalExpress,
            PayPalExpressExt,
            CartMgrExt,
            CouponFormExt,
            CartMgrPayPalExpressMixin
        ] = deps;

        return {
            listId: 'cart.core',
            widgetsDefinition: () => [
                ['payPalExpress', PayPalExpress],
                ['payPalExpress', PayPalExpressExt, 'payPalExpress'],
                ['cartMgr', CartMgrExt, 'cartMgr'],
                ['couponform', CouponFormExt, 'couponform'],
                ['cartMgr', CartMgrPayPalExpressMixin, 'cartMgr']

            ]
        };
    })
});

WidgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('content'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'content.widgets' */ 'core/content/ContactUsForm').then(d => d.default),
        import(/* webpackChunkName: 'content.widgets' */ 'widgets/forms/InputNumber').then(d => d.default),
        import(/* webpackChunkName: 'content.widgets' */ 'core/forms/InputEmailExt').then(d => d.default)
    ]).then(deps => {
        const [
            ContactUsForm,
            InputNumber,
            InputEmailExt
        ] = deps;

        return {
            listId: 'content.core',
            widgetsDefinition: () => [
                ['contactUsForm', ContactUsForm, 'basicForm'],
                ['inputNumber', InputNumber, 'basicInput'],
                ['inputEmail', InputEmailExt, 'inputEmail']
            ]
        };
    })
});
