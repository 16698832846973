import { TWidget } from 'widgets/Widget';

/**
 * @param Widget Base widget for extending
 * @returns EmitBusEvent widget
 */
export default function (Widget: TWidget) {
    /**
     * @category widgets
     * @subcategory global
     * @class EmitBusEvent
     * @augments Widget
     * @classdesc Could be used to trigger any global event using interaction element
     * <br>Could be useful to emit global events like `page.show.globalmodal` to notify
     * <br>concerned widgets, so they could change page layout class etc.
     * <br>Event emission could happens mostly on UI elements like links, buttons etc.
     * @property {string} data-widget - Widget name `emitBusEvent`
     * @property {string} data-bus-event-type - Global event type, which should be triggered
     * @property {string} data-event-click - Event listener to trigger `emitBusEvent` method
     * @property {string} data-url - any kind of additional properties
     * @example <caption>Example of EmitBusEvent widget usage</caption>
     * <a
     *     data-widget="emitBusEvent"
     *     data-bus-event-type="page.show.globalmodal"
     *     data-event-click.prevent="emitBusEvent"
     *     data-url="${URLUtils.url('Login-ShowPasswordResetModal', 'loginmodal', 1, 'rurl', pdict.rurl)}"
     * >
     *     ${Resource.msg('link.login.forgotpassword', 'login', null)}
     * </a>
     */
    class EmitBusEventOrderCancel extends Widget {
        prefs() {
            return {
                busEventType: '',
                ...super.prefs()
            };
        }

        /**
         * @description Emit event
         * @returns {void}
         */
        emitBusEvent() {
            const reason = (<HTMLSelectElement>document.getElementById('dwfrm_orderCancel_reason'))?.value;
            const orderID = (<HTMLElement>document.querySelectorAll('form.m-order_cancel')[0])?.dataset?.orderId;

            this.eventBus().emit('account.order.cancel.step3', { orderID: orderID, reason: reason });

            if (this.prefs().busEventType) {
                this.eventBus().emit(this.prefs().busEventType, this);
            }
        }
    }

    return EmitBusEventOrderCancel;
}
