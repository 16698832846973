type TOptionData = {
    name: string,
    value: string,
    id?: string
};

type InputSelectInstance = ReturnType<typeof import('widgets/forms/InputSelect').default>;

export default function (InputSelect: InputSelectInstance) {
    /**
     * @class InputSelectExt
     * @augments InputSelect
     * @subcategory forms
     * @classdesc Represents InputSelect component with tabindex handling
     */
    class InputSelectExt extends InputSelect {
        /**
         * @description Update options list
         * @param optionsData Options list
         */
        updateOptions(optionsData: TOptionData[]) {
            const field = this.ref('field').empty().get();

            if (!field) {
                return;
            }

            optionsData.forEach(optionData => {
                const option = document.createElement('option');

                option.value = optionData.value;
                option.innerHTML = optionData.name;

                if (optionData.id) {
                    option.dataset.id = optionData.id;
                }

                field.appendChild(option);
            });

            if (this.prefs().staticOptionEnabled) {
                const singleOption = optionsData.length === 1;

                this.ref('icon').toggle(!singleOption);

                if (singleOption) {
                    this.ref('field').addClass(this.prefs().staticOptionClass);
                    this.ref('field').removeClass(this.prefs().selectableOptionsClass);
                    this.ref('field').attr('tabindex', '-1');
                } else {
                    this.ref('field').addClass(this.prefs().selectableOptionsClass);
                    this.ref('field').removeClass(this.prefs().staticOptionClass);
                    this.ref('field').attr('tabindex', false);
                }
            }
        }
    }

    return InputSelectExt;
}
