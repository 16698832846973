/* global dataLayer */
/**
 * @description GTM base utils
 * @module base
 * @category widgets
 * @subcategory gtm/util
 */
export const pageProperties = {
    currentCurrency: '',
    searchList: 'Search Results'
};

/**
 * @description main method to push changes to dataLayer
 * @param  {...any} args - data object
 * @returns {void}
 */
export function report(...args) {
    // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'dataLayer'.
    if (typeof dataLayer !== 'undefined') {
        // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'dataLayer'.
        dataLayer.push(...args);
    }
}

/**
 * @description Gets product list value
 * @param {refElement} link - product tile
 * @returns {string} result
 */
export function getProductList(link) {
    let list = window.location.search.indexOf('q=') > -1 ? pageProperties.searchList : 'Products Listings';
    const itemList = link.data('list');

    switch (itemList) {
        case 'cart':
            list = 'Shopping Cart';
            break;
        case 'minicart':
            list = 'Mini cart';
            break;
        case 'wishlist':
            list = 'Wishlist';
            break;
        case 'orderconfirmation':
            list = 'Order confirmation';
            break;
        case 'orderdetails':
            list = 'Order details';
            break;
        case 'suggestions':
            list = 'Search Suggestions';
            break;
        default:
            break;
    }

    if (link.data('recommendation')) {
        list = 'Products Carousel';
    }

    return list;
}

/**
 * @description Sets tile position on the PLP \ Search page
 * @param {refElement} tileInfo - product tile
 * @param {string} pid - product ID
 * @returns {void}
 */
export function setTilePosition(tileInfo, pid) {
    pid = pid || tileInfo.id;

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'productPositions' does not exist on type... Remove this comment to see the full error message
    if (window.productPositions && window.productPositions[pid]) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'productPositions' does not exist on type... Remove this comment to see the full error message
        tileInfo.position = window.productPositions[pid];
    }
}

/**
 * @description Sends to dataLayer an information about product on product link click
 * @param {refElement} link - product link
 * @returns {void}
 */
export function trackProductClick(link) {
    const list = getProductList(link);
    const tileInfo = link.data('analytics');
    const pid = link.data('pid');

    if (!tileInfo) {
        return;
    }

    setTilePosition(tileInfo, pid);
    report({
        event: 'productClick',
        ecommerce: {
            currencyCode: pageProperties.currentCurrency,
            click: {
                actionField: { list },
                products: [tileInfo]
            }
        }
    });
}
