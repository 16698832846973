import { dialogMgr } from 'widgets/toolbox/dialogMgr';

type TPasswordResetForm = InstanceType<ReturnType<typeof import('widgets/account/PasswordResetForm').default>>;

/**
 * @param {AjaxFormResponseContainer} AjaxFormResponseContainer Base widget for extending
 * @returns {typeof AjaxFormResponseContainerMigrated} AjaxFormResponseContainerMigrated widget
 */
export default function (AjaxFormResponseContainer: ReturnType<typeof import('widgets/forms/AjaxFormResponseContainer').default>) {
    /**
     * @category widgets
     * @subcategory forms
     * @class AjaxFormResponseContainerMigrated
     * @augments Modal
     * @classdesc Represents AjaxFormResponseContainerMigrated component with next features:
     * 1. Used to rerender password reset modal and update hidden email field
     *
     * @property {string} data-widget - Widget name `ajaxFormResponseContainerMigrated`
     * @example <caption>Example of ajaxFormResponseContainerMigrated widget usage</caption>
     *   <div
     *       data-widget="ajaxFormResponseContainerMigrated"
     *       data-id="modalForMigratedCustomer"
     *       data-close-opened-modal="true"
     *   >
     *       <div data-ref="container" hidden="hidden"></div>
     *       <script type="template/mustache" data-ref="passwordResetMsg">
     *           <div class="b-dialog m-reset_password" data-ref="container">
     *              ...
     *           <div class="b-dialog-body">
     *                  <p class="b-form_section">
     *                      ${Resource.msg('passwordReset.migrate.message','login',null)}
     *                  </p>
     *
     *                  <p class="b-form_section">
     *                      {{customerEmail}}
     *                  </p>
     *
     *                  <isset name="formElement" value="${require('forms/formElement')}" scope="page"/>
     *                  <form
     *                      class="reset-password-form"
     *                      action="${URLUtils.url('Account-MigratedPasswordResetForm', 'isMigrated', true)}"
     *                      method="POST"
     *                      name="email-form"
     *                      id="email-form"
     *                      data-widget="passwordResetForm"
     *                      data-event-submit.prevent="handleSubmit"
     *                      data-widget-event-submitted="showModal"
     *                  >
     *                      form content ...
     *                  </form>
     *              </div>
     *
     *           </div>
     *       </script>
     *       <script type="template/mustache" data-ref="passwordResetSuccessfully">
     *           <div class="b-dialog m-reset_password" data-ref="container">
     *               <isinclude template="account/password/migratedPasswordResetModelSuccesfully"/>
     *           </div>
     *       </script>
     *   </div>
    */
    class AjaxFormResponseContainerMigrated extends AjaxFormResponseContainer {
        /**
         * @description Update email field
         * @param [templateData] data to be rendered in template
         */
        updateEmail(templateData: Record<string, unknown> | undefined) : void {
            if (templateData && templateData.showPasswordResetModalForMigratedCustomer) {
                this.getById('email-form', (passwordResetForm: TPasswordResetForm) => {
                    passwordResetForm.updateEmail(templateData.customerEmail as string);
                });
            }
        }

        /**
         * @description Get template name for rendering
         * @param templateData - data to be rendered in template
         */
        getTemplateForRendering(templateData : Record<string, unknown> | undefined) : string {
            return templateData && templateData.showPasswordResetModalForMigratedCustomer
                ? 'passwordResetMsg' : 'passwordresetSuccessfully';
        }

        /**
         * @description Show Modal and puts it to the top of opened modals hierarchy.
         * @param [templateData] data to be rendered in template
         * @param [cb] optional callback
         * @param [templateName] - template name for rendering
         */
        showModal(templateData: Record<string, unknown> | undefined, cb?: (() => void) | undefined): Promise<void> {
            if (templateData) {
                templateData.templateName = this.getTemplateForRendering(templateData);
            }

            return super.showModal(templateData).then(() => {
                this.updateEmail(templateData);
            });
        }
    }

    return AjaxFormResponseContainerMigrated;
}
