const hasContext = (/** @type {string} */context) => window.contexts.includes(context);

import widgetsMgr from 'widgets/widgetsMgr';

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('checkout'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'checkout.applepay' */ './widgets/checkout/PaymentAccordionItemApplePay').then(d => d.default)
    ]).then(deps => {
        const [
            PaymentAccordionItemApplePay
        ] = deps;

        return {
            listId: 'checkout.applepay',
            widgetsDefinition: () => [
                ['paymentAccordionItemApplePay', PaymentAccordionItemApplePay, 'paymentAccordionItem']
            ]
        };
    })
});

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('cart'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'checkout.applepay' */ './widgets/cart/ApplePayButtonContainer').then(d => d.default)
    ]).then(deps => {
        const [
            ApplePayButtonContainer
        ] = deps;

        return {
            listId: 'cart.applepay',
            widgetsDefinition: () => [
                ['applePayButtonContainer', ApplePayButtonContainer]
            ]
        };
    })
});

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('pdp'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'product.applepay' */ './widgets/product/ApplePayButton').then(d => d.default),
        import(/* webpackChunkName: 'product.applepay' */ './widgets/product/ApplePayButtonMixin').then(d => d.default)
    ]).then(deps => {
        const [ApplePayButton, ApplePayButtonMixin] = deps;

        return {
            listId: 'product.applepay',
            widgetsDefinition: () => [
                ['productDetail', ApplePayButtonMixin, 'productDetail'],
                ['applePayButton', ApplePayButton]
            ]
        };
    })
});
