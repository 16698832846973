import WidgetsMgr from 'widgets/widgetsMgr';
import 'widgets/widgetsList';

/*
Don't delete next comment, it's a placeholder for additional imports
See details in documentation/tools/WebpackConfiguration.md
 */
import 'plugin_ordermanagement/widgetsList.ts';
import 'plugin_applepay/applepayWidgetsList.js';
import 'plugin_applepay/applepayWidgetsList.js';
import 'plugin_storelocator_w/widgetsList.ts';
import 'plugin_wishlists_w/wishlistWidgetsList.ts';
import 'plugin_serviceworker_w/sw-registration.ts';
import 'plugin_ordermanagement/widgetsList.ts';
import 'plugin_instorepickup_w/storePickupWidgetsList.ts';
import 'int_gtm_w/clientGTM.ts';
import 'app_page_designer_w/pageDesignerWidgetsList.ts';
import 'core/widgetsList';
// import './widgets/widgetsList'; // Uncomment if required

// disable only in checkout
const preventLoadTypescript = document.querySelector('#preventTypescript') as HTMLElement;

if (!preventLoadTypescript) {
    WidgetsMgr.run();

    if (module.hot) {
        module.hot.accept(['widgets/widgetsList', 'core/widgetsList'], () => WidgetsMgr.restartWidgets());
    }
}
