import { timeout } from '../toolbox/util';
import { getJSONByUrl } from 'widgets/toolbox/ajax';
import { TWidget } from 'widgets/Widget';
/**
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} refElement
 * @typedef {ReturnType<typeof import('widgets/global/Button').default>} Button
 * @typedef {} BasicInput
 */

type TBasicInput = ReturnType<typeof import('widgets/forms/BasicInput').default>;
type basicInputInstance = InstanceType<TBasicInput>;

/**
 * @param Widget base widget class
 * @returns Basic Form class
 */
export default function (Widget: TWidget) {
    /**
     * @category widgets
     * @subcategory forms
     * @class BasicForm
     * @augments Widget
     * @classdesc Represents BasicForm component with next features:
     * 1. Allow show/hide Form progress bar
     * 2. Allow get submit button name, Form fields, Form action url
     * 3. Allow submit and handle submit Form
     * 4. Allow set value to Form fields
     * 5. Allow validate Form and Form fields
     * 6. Allow updates Form html body by sending an AJAX request to server with params object
     * BasicForm widget should contain {@link Button} widgets that implement submit Form button.
     * Widget has next relationship:
     * * Handle Form submit using method {@link BasicForm#handleSubmit} by event {@link Button#event:click} from {@link Button} widget.
     * @example <caption>Example of BasicForm widget usage</caption>
     * <form
     *     action="${URLUtils.url('Order-Track')}"
     *     method="GET"
     *     data-widget="form"
     *     data-event-submit="handleSubmit"
     * >
     *      ... form contents
     *    <button
            data-widget="button"
            type="submit"
            data-widget-event-click="handleSubmit"
            data-event-click.prevent="handleClick"
          >
            ...Button name
          </button>
     * </form>
     * @property {string} data-widget - Widget name `form`
     * @property {string} data-event-submit - Event listener for form submission
     */
    class BasicForm extends Widget {
        prefs() {
            return {
                submitEmpty: true,
                emptyFormErrorMsg: '',
                submitButton: 'submitButton',
                errorMessageLabel: 'errorMessageLabel',
                SUBMITTING_TIMEOUT: 5000,
                formDefinitionUrl: '',
                ...super.prefs()
            };
        }

        /**
         * @description Set aria-busy attribute value true
         * @returns {void}
         */
        showProgressBar() {
            this.ref('self').attr('aria-busy', 'true');
        }

        /**
         * @description Set aria-busy attribute value false
         * @returns {void}
         */
        hideProgressBar() {
            this.ref('self').attr('aria-busy', 'false');
        }

        /**
         * @description Get submit button name
         * @returns {string|null|undefined} Submit button name
         */
        getSubmitButtonName() {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'submitButtonName' does not exist on type... Remove this comment to see the full error message
            return this.submitButtonName;
        }

        /**
         * @description Initialize widget logic
         * @returns {void}
         */
        init() {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'submitButtonName' does not exist on type... Remove this comment to see the full error message
            this.submitButtonName = null;
            super.init();
            /**
             * Below code is needed to initiate correct comparison configuration for certain fields
             * It iterates through all BasicInput children and if it finds the compare data in validation -
             * it will set necessary data to a proper child BasicInput widget
            */

            this.eachField(widget => {
                const widgetValidation = widget.prefs().validationConfig || {};

                if (!Array.isArray(widgetValidation.compareWith)) { return; }

                widgetValidation.compareWith.forEach((compareFieldItem, index) => {
                    this.getById(compareFieldItem.field, targetWidget => {
                        if (targetWidget.length) {
                            const compareOptions = {
                                field: compareFieldItem.field,
                                msg: widgetValidation.errors.compareWith[index] || '',
                                equality: compareFieldItem.equality
                            };

                            widget.data('setMatchCmp', targetWidget, compareOptions);
                        }
                    });
                });
            });
        }

        /**
         * @description Process children fields with callback passed as param
         * @param fn - callback function
         * @returns List of fields processing result
         */
        eachField(fn: (n: basicInputInstance) => unknown) : unknown[] {
            return this.getFields().map(item => {
                return fn(item);
            });
        }

        /**
         * @description Get form field instances
         */
        getFields() : basicInputInstance[] {
            const BasicInput = <TBasicInput> this.getConstructor('basicInput');
            const items = this.items ? this.items : [];

            return items.filter((item: unknown): item is basicInputInstance => item instanceof BasicInput);
        }

        /**
         * @description Save submit button
         * @param {HTMLInputElement} el submit button element
         * @returns {void}
         */
        saveSubmitButton(el) {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'submitButtonName' does not exist on type... Remove this comment to see the full error message
            this.submitButtonName = el.name;
        }

        /**
         * @description Submit Form
         * @returns {void}
         */
        submit() {
            /**
             * @type {HTMLElement|undefined}
             */
            const elem = this.ref(this.prefs().submitButton).get();

            if (elem) {
                elem.click();
            }
        }

        /**
         * @description Handle submit Form
         * @emits BasicForm#submit
         * @param {refElement} _el event source element
         * @param {(Event|undefined)} event event instance if DOM event
         * @returns {void}
         */
        handleSubmit(_el, event) {
            this.clearError();

            const valid = this.isChildrenValid();

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'submitting' does not exist on type 'Basi... Remove this comment to see the full error message
            if ((!valid || this.submitting) && (event && event instanceof Event)) {
                event.preventDefault();

                return;
            }

            this.ref(this.prefs().submitButton).disable();

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'submitting' does not exist on type 'Basi... Remove this comment to see the full error message
            this.submitting = true;
            this.onDestroy(timeout(() => {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'submitting' does not exist on type 'Basi... Remove this comment to see the full error message
                this.submitting = false;
            }, this.prefs().SUBMITTING_TIMEOUT));
            this.emit('submit');
        }

        /**
         * @description Get Form fields
         * @returns {object} Object contains form fields
         */
        getFormFields() {
            /**
             * @type {{[x: string]: string}}
             */
            const fields = {};

            this.eachField(widget => {
                if (!(widget.skipSubmission && widget.skipSubmission())) {
                    const name = widget.getName && widget.getName();

                    if (name) {
                        fields[name.toString()] = widget.getValue();
                    }
                }
            });

            return fields;
        }

        /**
         * @description Sets related fields values, can be executed silently, without triggering `change` event
         * @param {object} formFields - Structured object with name: value pairs for input fields
         * @param {(boolean|undefined)} [silently] - if set to `true` - input should not be validated against a new value
         * @returns {void}
         */
        setFormFields(formFields, silently = false) {
            this.eachField(widget => {
                const name = widget.getName && widget.getName();

                widget.setValue(formFields[name], silently);
            });
        }

        /**
         * @description Check is Form fields valid
         * @param {Function} [cb] callback called if child inputs are valid
         * @returns {boolean} - boolean value is Form input valid
         */
        isChildrenValid(cb?) {
            let valid = true;

            this.eachField(item => {
                if (typeof item.validate === 'function' && !item.validate()) {
                    if (valid && item.setFocus) {
                        item.setFocus();
                    }

                    valid = false;
                }
            });

            if (valid && typeof cb === 'function') {
                cb();
            }

            if (!this.prefs().submitEmpty) {
                const fieldsValues = this.getFormFields();

                if (Object.keys(fieldsValues).every((key) => !fieldsValues[key])) {
                    valid = false;
                    this.ref(this.prefs().errorMessageLabel)
                        .setText(this.prefs().emptyFormErrorMsg);
                    this.ref(this.prefs().errorMessageLabel).show();
                }
            }

            return valid;
        }

        /**
         * @description Form validate
         * @returns {boolean} boolean value is Form input valid
         */
        validate() {
            return this.isChildrenValid();
        }

        /**
         * @description Check is Form valid based on Form fields validation
         * @returns {boolean} boolean value is Form valid
         */
        isValid() {
            let valid = true;

            this.eachField((itemComponent) => {
                if (typeof itemComponent.isValid === 'function'
                    && !itemComponent.isValid()
                ) {
                    valid = false;

                    return false;
                }

                return true;
            });

            return valid;
        }

        /**
         * @description Interface that can be override
         */
        setFocus(): void {
            throw Error('setFocus method not implemented');
        }

        /**
         * @description Get Form action url
         * @returns form action url
         */
        getFormUrl(): string {
            const formURL = this.ref('self').attr('action');

            return formURL.toString();
        }

        /**
         * @description Clear Form Error
         * @param {string} refID RefElement ID
         * @returns {void}
         */
        clearError(refID = 'errorMessageLabel') {
            this.ref(refID)
                .hide()
                .setText('');
        }

        /**
         * @description Updates form html body by sending an AJAX request to server with params object
         * <br>(possible param key is `countryCode`)
         * <br>Obtained template injected instead of old fields
         * <br>Data, entered in fields previosly will be restored
         * @param {object} params - request parameters
         * @param {string} params.countryCode - A country code to get country-specific form
         * @returns {Promise<boolean>} - Promise object represents rendering result
         */
        updateFormData(params) {
            const formDefinitionUrl = this.prefs().formDefinitionUrl;

            if (formDefinitionUrl && params) {
                return new Promise((resolve, reject) => {
                    getJSONByUrl(formDefinitionUrl, params, true).then((response) => {
                        if (response.formDefinition) {
                            const formFields = this.getFormFields();

                            this.render('', {}, this.ref('fieldset'), <string> response.formDefinition).then(() => {
                                this.eachField(widget => {
                                    const name = widget.getName();
                                    const value = widget.data('localized') ? '' : formFields[name];

                                    widget.setValue(value, true);
                                });
                                resolve(true);
                                setTimeout(() => this.formDataUpdated(), 0);
                            }).catch(() => {
                                reject(new Error('Form definition rendering error'));
                            });
                        } else {
                            reject(new Error('Form definition is empty'));
                        }
                    });
                });
            }

            return new Promise((resolve) => {
                resolve(true);
                this.formDataUpdated();
            });
        }

        /**
         * @description Template method called once form definitions were reloaded
         * @returns {void}
         */
        formDataUpdated() {

        }
    }

    return BasicForm;
}
