/**
 * @typedef {InstanceType<ReturnType<typeof import('widgets/forms/AjaxForm').default>>} ajaxForm
 */

type TAjaxForm = InstanceType<ReturnType<typeof import('widgets/forms/AjaxForm').default>>;

/**
 * @param {Modal} Modal Base widget for extending
 * @returns {typeof AjaxFormResponseContainer} AjaxFormResponseContainer widget
 */
export default function (Modal: ReturnType<typeof import('widgets/global/Modal').default>) {
    /**
     * @category widgets
     * @subcategory forms
     * @class AjaxFormResponseContainer
     * @augments Modal
     * @classdesc Represents AjaxFormResponseContainer component with next features:
     * 1. Used to rerender part of page after success response from server using mustache template
     *
     * Widget has next relationship:
     * * Rerender part of the page using method {@link AjaxFormResponseContainer#onSuccessSubmit} by event {@link AjaxForm#event:submit} from {@link AjaxForm} widget.
     * @property {string} data-widget - Widget name `AjaxFormResponseContainer`
     * @example <caption>Example of AjaxFormResponseContainer widget usage</caption>
     *  <div
     *      class="l-account m-small m-top_margin b-account"
     *      data-widget="ajaxFormResponseContainer"
     *  >
     *      <div
     *          class="b-form-message b-message m-error"
     *          role="alert"
     *      >
     *          ${Resource.msg('passwordReset.warning', 'login', null)}
     *      </div>

     *      <div
     *          class="b-form-message b-message m-error"
     *          hidden="hidden"
     *          data-ref="errorMessageLabel"
     *          role="alert"
     *      ></div>

     *      <h1 class="b-account-title m-top_margin">
     *          ${Resource.msg('passwordReset.title', 'login', null)}
     *      </h1>

     *      <form
     *          class="b-form m-reset_password"
     *          action="${URLUtils.url('Account-PasswordResetDialogForm', 'mobile', pdict.mobile)}"
     *          method="POST"
     *          data-widget="ajaxform"
     *          data-event-submit.prevent="handleSubmit"
     *          data-widget-event-submit="onSuccessSubmit"
     *          name="email-form"
     *          id="email-form"
     *      >
     *          form content....
     *          <div class="b-form_section">
     *              <button
     *                  type="submit"
     *                  class="b-button m-width_full"
     *                  data-widget="button"
     *                  data-widget-event-click="handleSubmit"
     *                  data-event-click.prevent="handleClick"
     *                  data-id="submitButton"
     *                  >
     *                  ${Resource.msg('form.passwordReset.submit','forms',null)}
     *              </button>
     *          </div>
     *      </form>
     *      <script type="template/mustache" data-ref="template">
     *          <div
     *              class="l-account m-small m-top_margin b-account"
     *              data-widget="ajaxFormResponseContainer"
     *          >
     *              <h1 class="b-account-title">
     *                  {{receivedMsgHeading}}
     *              </h1>
     *              <p>{{receivedMsgBody}}</p>
     *          </div>
     *      </script>
     *  </div>
     */
    class AjaxFormResponseContainer extends Modal {
        /**
         * @description Get response from server and render it
         * @listens AjaxForm#submit
         * @param _form - ajaxForm Widget
         * @param data - response form server
         * @returns Promise object represents submission response rendering result
         */
        onSuccessSubmit(_form: TAjaxForm, data : Record<string, unknown>) : Promise<void> {
            return this.render(undefined, data);
        }
    }

    return AjaxFormResponseContainer;
}
