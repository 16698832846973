/**
 * @description Base InputDate implementation
 * @param MaskableInput Base widget for extending
 * @returns Input Date class
 */
export default function (MaskableInput: ReturnType<typeof import('widgets/forms/BasicInput').default>) {
    /**
     * @category widgets
     * @subcategory forms
     * @class InputDate
     * @augments MaskableInput
     * @classdesc Input type Date implementation. Represents input `date` element together with widget-related HTML markup.
     * HTML structure assembled on backend and injected into resulted html markup by using `formElement` component
     * and dynamic forms configuration JSON.
     * @property {string} data-widget - Widget name `inputDate`
     * @property {string} data-mask-type - Type of mask. In this case, 'Date' indicating the mask is for dates.
     * @property {string} data-date-format - Format for the date. For example, 'YYYY-MM-DD'.
     * @example <caption>Insertion of InputDate inside ISML templates</caption>
     * <isset name="formElement" value="${require('forms/formElement')}" scope="page"/>
     * ...
     * <form>
     *     ...
     *     <isprint value="${
     *         formElement(pdict.addressForm.birthday, pdict.addressFormOptions).render()
     *     }" encoding="off"/>
     *     ...
     * </form>
     * @example <caption>Resulted HTML structure for InputDate</caption>
     *   <div data-widget="inputDate" class="b-form_section m-required m-invalid"
     *       data-id="dwfrm_shipping_shippingAddress_addressFields_birthday"
     *       data-validation-config="... validation config"
     *       data-mask-type="Date" data-date-format="YYYY-MM-DD"
     *   >
     *       <label class="b-form_section-label" for="dwfrm_shipping_shippingAddress_addressFields_birthday">
     *           <span class="b-form_section-required" aria-hidden="true">
     *               *
     *           </span>
     *           Birthday
     *       </label>
     *       <input data-ref="field" id="dwfrm_shipping_shippingAddress_addressFields_birthday"
     *           type="date" class="b-input m-invalid" placeholder="" aria-describedby="dwfrm_shipping_shippingAddress_addressFields_birthday-error"
     *           name="dwfrm_shipping_shippingAddress_addressFields_birthday" required="" value=""
     *           aria-required="true" autocomplete="bday" data-event-blur="validate"
     *       >
     *       <div role="alert" class="b-form_section-message" data-ref="errorFeedback" id="dwfrm_shipping_shippingAddress_addressFields_birthday-error">This field is required.</div>
     *       <span id="dwfrm_shipping_shippingAddress_addressFields_birthday-caption" class="b-form_section-caption">
     *           Please select your birthday
     *       </span>
     *   </div>
     */
    class InputDate extends MaskableInput {
        /**
         * @description Overloaded `setValue` in order to handle date format
         * @param {(string|undefined)} [newVal] - set this value to input
         * @param {(boolean|undefined)} [silently] - if set to `true` - input should not be validated against a new value
         * @returns {void}
         */

        prefs() {
            return {
                ...super.prefs()
            };
        }

        setValue(newVal = '', silently = false) {
            super.setValue(newVal, silently);
            // No need for additional processing for date input
        }

        /**
         * @description Get message by state validity
         * @param state Validity state
         */
        getValidationStateMsg(state: ValidityState) : string {
            const validation = this.prefs().validationConfig;

            if ((state.patternMismatch || state.typeMismatch)) {
                return validation.errors.parse || validation.errors.security;
            } else if ((state.rangeOverflow || state.rangeUnderflow || state.tooLong || state.tooShort)) {
                if (state.rangeOverflow || state.tooLong) {
                    return validation.errors.maxLength;
                } else if (state.rangeUnderflow || state.tooShort) {
                    return validation.errors.minLength;
                }
            } else if (state.valueMissing) {
                return validation.errors.required;
            } else if (state.badInput) {
                return validation.errors.parse;
            }

            return '';
        }

        /**
         * @description Get input value
         * @returns {string} - return input value
         */
        getValue() {
            return super.getValue(); // No additional processing needed for date input
        }
    }

    return InputDate;
}
