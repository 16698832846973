/* eslint-disable max-len, indent, sonarjs/no-duplicate-string */

const hasContext = (/** @type {string} */ctx) => window.contexts.includes(ctx);

import widgetsMgr from 'widgets/widgetsMgr';

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('checkout'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'checkout.storepickup' */ './widgets/checkout/CheckoutMgrStorePickupMixin').then(d => d.default),
        import(/* webpackChunkName: 'checkout.storepickup' */ './widgets/checkout/TabsShippingMixin').then(d => d.default),
        import(/* webpackChunkName: 'checkout.storepickup' */ './widgets/checkout/ShippingFormStorePickupMixin').then(d => d.default),
        import(/* webpackChunkName: 'checkout.storepickup' */ './widgets/checkout/BillingFormStorePickupMixin').then(d => d.default),
        import(/* webpackChunkName: 'checkout.storepickup' */ '../../../../../plugin_storelocator_w/cartridge/client/default/js/widgets/storelocator/StoreSearchForm').then(d => d.default),
        import(/* webpackChunkName: 'checkout.storepickup' */ '../../../../../plugin_storelocator_w/cartridge/client/default/js/widgets/storelocator/SearchByPlace').then(d => d.default),
        import(/* webpackChunkName: 'checkout.storepickup' */ './widgets/storePickup/StoreSearchFormStorePickupMixin').then(d => d.default)
    ]).then(deps => {
        const [
            CheckoutMgrStorePickupMixin,
            TabsShippingMixin,
            ShippingFormStorePickupMixin,
            BillingFormStorePickupMixin,
            StoreSearchForm,
            SearchByPlace,
            StoreSearchFormStorePickupMixin
        ] = deps;

        return {
            listId: 'checkout.storepickup',
            widgetsDefinition: () => [
                ['checkoutMgr', CheckoutMgrStorePickupMixin, 'checkoutMgr'],
                ['shippingTypeTabs', TabsShippingMixin, 'tabs'],
                ['shippingForm', ShippingFormStorePickupMixin, 'shippingForm'],
                ['billingForm', BillingFormStorePickupMixin, 'billingForm'],
                ['storeSearchForm', StoreSearchForm, 'ajaxform'],
                ['storePickupSearchForm', StoreSearchFormStorePickupMixin, 'storeSearchForm'],
                ['searchByPlace', SearchByPlace, 'inputText']
            ]
        };
    })
});

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('pdp'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'product.storepickup' */ './widgets/product/ProductStorePickupForm').then(d => d.default)
    ]).then(deps => {
        const [
            ProductStorePickupForm
        ] = deps;

        return {
            listId: 'product.storepickup',
            widgetsDefinition: () => [
                ['productStorePickupForm', ProductStorePickupForm, 'ajaxform']
            ]
        };
    })
});
