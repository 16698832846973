import { pageProperties, report } from './utils/base';
import { init as initEvents } from './components/events';
import { reportProductImpressions, reportPromoImpressions } from './components/impressions';

/**
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} refElement
 */
/**
 * @description clientGTM
 * @module clientGTM
 * @category widgets
 * @subcategory gtm
 */
/**
 * @description Init GTM callback
 * @returns {void}
 */
function init() {
    if (pageProperties.currentCurrency === '') {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'gtmUserData' does not exist on type 'Win... Remove this comment to see the full error message
        pageProperties.currentCurrency = window.gtmUserData?.currencyCode;
    }

    initEvents();
    reportProductImpressions();

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'gtmDelayedEvent' does not exist on type ... Remove this comment to see the full error message
    if (window.gtmDelayedEvent) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'gtmDelayedEvent' does not exist on type ... Remove this comment to see the full error message
        report(window.gtmDelayedEvent);
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'gtmDelayedEvent' does not exist on type ... Remove this comment to see the full error message
        delete window.gtmDelayedEvent;
    }

    reportPromoImpressions();
}

// @ts-expect-error ts-migrate(2339) FIXME: Property 'dataLayer' does not exist on type 'Windo... Remove this comment to see the full error message
if (window.dataLayer) {
    if (document.readyState === 'loading') {
        /**
         * @description Init gtm once dom content loaded
         * @listens dom:DOMContentLoaded
         */
        document.addEventListener('DOMContentLoaded', () => {
            setTimeout(() => {
                init();
            }, 0);
        }, { once: true });
    } else {
        init();
    }
}
