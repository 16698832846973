/**
 * @param EmailSubscribe Base widget for extending
 * @returns EmailSubscribeExt widget
 */
export default function (EmailSubscribe: ReturnType<typeof import('widgets/forms/ajax/EmailSubscribe').default>) {
    /**
   * @category widgets
   * @subcategory ajax
   * @class EmailSubscribeExt
   * @augments EmailSubscribe
   * @classdesc Serves email subscription form in footer.
   */
    class EmailSubscribeExt extends EmailSubscribe {
        prefs() {
            return {
                fromTitle: 'formTitle',
                ...super.prefs()
            };
        }

        /**
       * @description Hide form title on success response
       * @param data Server JSON response once form submitted
       */
        onSubmitted(data) {
            if (data.success) {
                this.ref('formTitle').hide();
            }

            super.onSubmitted(data);
        }
    }

    return EmailSubscribeExt;
}
