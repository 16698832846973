/**
 * @description We need to normalize path because on dev - it would be /, but on
 * sandbox it usually /s/SiteId/
 * @param {string} relativeUrl - relative url to service worker
 * @returns {string} - normalized url to service worker
 */
function normalizeUrl(relativeUrl) {
    const sandboxUrlType = window.location.pathname.match(/(\/s\/[^/]+)\/?/);

    return (sandboxUrlType ? sandboxUrlType[1] : '') + relativeUrl;
}

export default function () {
    if ('serviceWorker' in navigator) {
        let serviceWorkerInstance;

        // eslint-disable-next-line spellcheck/spell-checker
        if (window.location.pathname.indexOf('on/demandware.store') > -1) {
            // Skip service worker registration for not user friendly URLs
            return;
        }

        navigator.serviceWorker.register(normalizeUrl('/service-worker.js'))
            .then((registration) => {
                serviceWorkerInstance = registration;
            }).catch(() => {
                if (!serviceWorkerInstance) {
                    return;
                }

                serviceWorkerInstance.unregister().then(isUnregistered => {
                    if (isUnregistered) {
                        serviceWorkerInstance = null;
                    }
                });
            });
    }
}
