import { TWidget } from 'widgets/Widget';

/**
 * @param Widget Base widget for extending
 * @returns ConnectionMonitor class
 */
export default function (Widget: TWidget) {
    /**
     * @category widgets
     * @subcategory global
     * @class ConnectionMonitor
     * @augments Widget
     * @classdesc Connection monitor component to notify the user about the loss of connection
     */
    class ConnectionMonitor extends Widget {
        prefs() {
            return {
                connectionTimeoutValue: 3000,
                ...super.prefs()
            };
        }

        init(): void {
            super.init();

            this.ev<HTMLElement, Event>('offline', this.onOffline, window);
            this.ev<HTMLElement, Event>('online', this.onOnline, window);
        }

        /**
         * @description Shows Alert message in case if client goes offline
         */
        onOffline(): void {
            this.render('template', undefined, this.ref('container'));
        }

        /**
         * @description Calls hiding Alert message method after the delay time
         */
        onOnline(): void {
            setTimeout(() => {
                this.hideAlert();
            }, this.prefs().connectionTimeoutValue);
        }

        /**
         * @description Hides an Alert message
         */
        hideAlert(): void {
            this.ref('container').hide();
        }
    }

    return ConnectionMonitor;
}
