/* eslint-disable spellcheck/spell-checker */
/**
 * @typedef {InstanceType<ReturnType<typeof import('widgets/forms/InputText').default>>} inputText
 */

import { submitFormJson, errorFallbackMessage } from 'widgets/toolbox/ajax';
import { scrollWindowTo, scrollToTop } from 'widgets/toolbox/scroll';
type IButton = InstanceType<ReturnType<typeof import('widgets/global/Button').default>>;

/**
 * @param AjaxForm Base widget for extending
 * @returns EmailSubscribe widget
 */
export default function (AjaxForm: ReturnType<typeof import('widgets/forms/AjaxForm').default>) {
    /**
     * @category widgets
     * @subcategory ajax
     * @class EmailSubscribe
     * @augments AjaxForm
     * @classdesc Serves email subscription form in footer.
     * The main purpose EmailSubscribe widget is to allow send/validate the request to server and handles server response
     * @property {string} data-widget - Widget name `emailSubscribe`
     * @property {string} data-event-submit - Event listener for form submission
     * @example <caption>Example of EmailSubscribe widget usage</caption>
     * <form action="${URLUtils.url('EmailSubscribe-Subscribe')}" method="POST" name="subscribe-form"
     *     data-widget="emailSubscribe"
     *     data-event-submit.prevent="handleSubmit">
     *     <div class="input-group" data-ref="formContent">
     *         ... subscription form content
     *     </div>
     *     <div data-ref="successMessage"></div>
     * </form>
     */
    class EmailSubscribe extends AjaxForm {
        prefs() {
            return {
                signupEmail: 'dwfrm_emailsubscribe_email',
                formContent: 'formContent',
                successBlock: 'successBlock',
                successMessage: 'successMessage',
                agreeToPrivacy: 'agreeToPrivacy',
                accessibilityAnnouncementMsg: '',
                ...super.prefs()
            };
        }

        /**
         * @description Handles email input
         * @param {inputText} el event source element
         * @returns {void}
         */
        onEmailInput(el) {
            const entered = el && el.getValue();

            if (entered && entered.length) {
                this.showMoreInput();
            }
        }

        /**
         * @description Show agree to privacy block
         * @returns {void}
         */
        showMoreInput() {
            this.ref('agreeToPrivacy').show();
            this.ref('firstName').show();
            this.ref('lastName').show();
        }

        /**
         * @description Handles server response
         * @emits "alert.show"
         * @param {object} data Server JSON response once form submitted
         * @returns {void}
         */
        onSubmitted(data) {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'grecaptcha' does not exist on type 'Widget... Remove this comment to see the full error message
            if (data && data.usedCaptcha && grecaptcha) {
                this.resetCaptchaWidgets();
            }

            if (data.success) {
                this.ref('formContent').hide();
                this.ref('successMessage')
                    .setText(data.msg)
                    .show();
                /**
                 * @description Global event to show alert
                 * @event "alert.show"
                 */
                this.eventBus().emit('alert.show', {
                    accessibilityAlert: this.prefs().accessibilityAnnouncementMsg
                });
                this.eventBus().emit('highlighter.update');
                this.eventBus().emit('account.newsletter');
            }

            if (!data.success) {
                if (data.fieldErrors) {
                    Object.entries(data.fieldErrors).forEach(([name, errorMsg]) => {
                        // @ts-expect-error ts-migrate(2339) FIXME: Property 'setError' does not exist on type 'Widget... Remove this comment to see the full error message
                        this.getById(name, formField => formField.setError(errorMsg));
                    });
                }

                if (data.msg) {
                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'setError' does not exist on type 'Widget... Remove this comment to see the full error message
                    this.getById(this.prefs().signupEmail, inputEmail => inputEmail.setError(data.msg));
                }
            }
        }

        init() {
            super.init();
            window.addEventListener('emailSubscribeSubmit', (e: Event) => {
                this.handleSubmit((e as CustomEvent).detail);
            });
        }

        /**
         * @description Handles an error, which happens during request (for ex. 500 response)
         * @param error - error, which happened during request
         */
        onError(error: Error): void {
            this.setError(error.message || errorFallbackMessage);
            const errorElement = this.ref(this.prefs().errorMessageLabel).get();

            if (errorElement) {
                scrollWindowTo(errorElement, true);
            } else {
                scrollToTop();
            }

            this.resetCaptchaWidgets();
        }

        /**
         * @param token
         * @description Handles submit Form
         * @returns {Promise<object|null>} Promise object represents server response for shipping methods updating
         */
        handleSubmit(token?) {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'submitting' does not exist on type 'Email... Remove this comment to see the full error message
            if (this.isChildrenValid() && !this.submitting) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'submitting' does not exist on type 'Email... Remove this comment to see the full error message
                this.submitting = true;
                this.showProgressBar();
                this.getById(this.prefs().submitButton, (submitButton: IButton) => submitButton.busy());
                this.ref(this.prefs().errorMessageLabel).hide();
                const formData = this.getFormFields();

                if (token) {
                    formData['g-recaptcha-response'] = token;
                }

                return submitFormJson(
                    this.getFormUrl(),
                    formData,
                    this.ref('self').attr('method') === 'GET' ? 'GET' : 'POST'
                )
                    .then((response) => {
                        this.onSubmitted(response);

                        return response;
                    })
                    .catch(this.onError.bind(this))
                    .finally(this.afterSubmission.bind(this));
            }

            return Promise.resolve(null);
        }

        resetCaptchaWidgets = () => {
            const recaptchaElements = document.querySelectorAll('.g-recaptcha');

            recaptchaElements.forEach(function (object) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'grecaptcha' does not exist on type 'Widget... Remove this comment to see the full error message
                grecaptcha.reset(object);
            });
        };
    }

    return EmailSubscribe;
}
