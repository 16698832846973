import { TWidget } from 'widgets/Widget';

export default function (Widget: TWidget) {
    class PreHeaderScript extends Widget {
        // eslint-disable-next-line sonarjs/cognitive-complexity
        init(): void {
            const multipleTextContainer = document.getElementById('multiple-text');

            if (multipleTextContainer) {
                const multipleTextContainerElements = multipleTextContainer.getElementsByTagName('span');
                const preHeaderScript = this.ref('self').get();
                const timer = parseInt(preHeaderScript?.getAttribute('data-timer') ?? '15000', 10); // 15 seconds

                if (multipleTextContainerElements.length > 1) {
                    // create array with all text list
                    const multipleTextContainerElementsList: string[] = [];

                    Array.from(multipleTextContainerElements).forEach(element => {
                        multipleTextContainerElementsList.push(element.innerHTML);
                    }, this);

                    // remove all elements except the first
                    while (multipleTextContainerElements.length > 1) {
                        multipleTextContainer.removeChild(multipleTextContainerElements[1]);
                    }

                    // add visible class (show element)
                    let count = 0;
                    let intervalId: NodeJS.Timer;

                    const startSlideshow = () => {
                        intervalId = setInterval(() => {
                            count++;
                            const textElement = multipleTextContainer.getElementsByTagName('span')[0];

                            if (!textElement) {
                                return;
                            }

                            textElement.classList.remove('show');
                            setTimeout(() => {
                                const currentItem = multipleTextContainerElementsList[count % multipleTextContainerElementsList.length];

                                textElement.innerHTML = currentItem;
                                textElement.classList.add('show');
                            }, 400);
                        }, timer);
                    };

                    const stopSlideshow = () => {
                        clearInterval(intervalId as NodeJS.Timeout);
                    };

                    // Start the slideshow
                    multipleTextContainer.removeAttribute('hidden');
                    startSlideshow();
                    multipleTextContainer.addEventListener('mouseover', stopSlideshow);
                    multipleTextContainer.addEventListener('mouseout', startSlideshow);
                }
            }
        }

        // onDestroy(arg0: () => any) {
        //     throw new Error("Method not implemented.");
        // }
    }

    return PreHeaderScript;
}
