/**
 * @description Base InputTextarea implementation
 * @param InputText Base widget for extending
 * @returns Input Textarea class
 */
export default function (InputText: ReturnType<typeof import('widgets/forms/InputText').default>) {
    /**
     * @class InputTextarea
     * @augments InputText
     * @classdesc Input type InputTextarea implementation. Represents input `textarea` element together with widget-related HTML markup.
     * HTML structure assembled on backend and injected into resulted html markup by using `formElement` component
     * and dynamic forms configuration JSON.
     * Has a possibility to display number of entered symbols and how many of them are left to enter.
     * @property {string} data-widget - Widget name `inputTextarea`
     * @example <caption>InputTextarea definition in dynamicforms.json</caption>
     * ...
     * // fields -> input -> textarea
     * textarea: {
     *     extends: 'fields.input.base',
     *     validation: {
     *         'errors.security': 'validation.errors.parse'
     *     },
     *     element: {
     *         minLength: 1,
     *         maxLength: 256,
     *         type: 'textarea'
     *     }
     * },
     * ...
     * // forms -> shipping -> shippingAddress
     * giftMessage: {
     *     widget: {
     *         attributes: {
     *             'data-id': 'giftMessage'
     *         },
     *         classes: 'gift-message'
     *     },
     *     extends: 'fields.input.textarea',
     *     'label.text': 'form.gift.message',
     *     'label.showOptionalMarker': true,
     *     element: {
     *         maxcounter: true,
     *         attributes: {
     *             'data-event-input': 'onInput'
     *         },
     *         placeholder: 'form.gift.placeholder'
     *     }
     * }
     * @example <caption>Insertion of InputTextarea inside ISML templates</caption>
     * <isset name="formElement" value="${require('forms/formElement')}" scope="page"/>
     * ...
     * <form>
     *     ...
     *     <isprint value="${
     *         formElement(pdict.addressForm.giftMessage).render()
     *     }" encoding="off"/>
     *     ...
     * </form>
     * @example <caption>Resulted HTML structure for InputTextarea</caption>
     * <div data-widget="inputTextarea" data-id="giftMessage"
     *     class="b-form_section gift-message m-valid" data-validation-config="... validation config"
     * >
     *     <label class="b-form_section-label" for="dwfrm_shipping_shippingAddress_giftMessage">
     *         Gift message (optional)
     *     </label>
     *     <textarea
     *          id="someID"
     *          class="b-textarea m-valid"
     *          data-ref="field"
     *          placeholder="Enter your text..."
     *          aria-describedby="someID-error"
     *          rows="5"
     *          name="someName"
     *          maxlength="256"
     *          minlength="1"
     *          data-event-input="onInput"
     *          data-event-blur="validate"
     *          data-prevent-multi-line="true"
     *     ></textarea>
     *     <div class="b-form_section-counter">
     *         <span class="b-form_section-counter_value" data-ref="maxcounter">256</span> characters left
     *     </div>
     *     <div role="alert" hidden="hidden" class="b-form_section-message" data-ref="errorFeedback" id="someID-error"></div>
     * </div>
     */
    class InputTextarea extends InputText {
        prefs() {
            return {
                preventMultiLine: false,
                ...super.prefs()
            };
        }

        init() {
            super.init();

            this.onInput();
        }

        /**
         * @description On `input` InputTextarea handler
         * @listens dom#input
         */
        onInput(): void {
            const currentInputValue = this.ref('field').val().toString();

            this.stripNewLines(currentInputValue);

            this.updateCounter(currentInputValue);
        }

        /**
         * @description Gives a possibility to avoid new line to be inserted to textarea
         * @param currentInputValue textarea value
         */
        stripNewLines(currentInputValue: string): void {
            if (this.prefs().preventMultiLine) {
                this.ref('field').val(currentInputValue.replace(/\r?\n/g, ''));
            }
        }

        /**
         * @description Gives a possibility to check how many symbols are entered
         * and restricts further input in case if max allowed characters reached.
         * @param currentInputValue textarea value
         */
        updateCounter(currentInputValue: string): void {
            if (!this.has('maxcounter')) {
                return;
            }

            const maxLength = Number(this.ref('field').attr('maxlength'));

            if (currentInputValue.length > maxLength) {
                /*
                 * Additional handling of input field value on paste to
                 * avoid issues caused by browser incorrect value handling
                 * https://bugs.webkit.org/show_bug.cgi?id=182725
                 */
                this.ref('field').val(currentInputValue.slice(0, maxLength));
            }

            const charactersLeft = maxLength - this.ref('field').val().length;

            this.ref('maxcounter').setText(String(charactersLeft));
        }
    }

    return InputTextarea;
}
