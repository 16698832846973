/* eslint-disable indent */
const hasContext = (context: string) => window.contexts.includes(context);

import widgetsMgr from 'widgets/widgetsMgr';

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('account'),
    load: () => Promise.all([
            import(/* webpackChunkName: 'account.widgets' */ './widgets/account/OrderCancellationForm').then(d => d.default),
            import(/* webpackChunkName: 'account.widgets' */ './widgets/account/OrderHistoryMgrSOM').then(d => d.default)
        ])
        .then(deps => {
            const [
                OrderCancellationForm,
                OrderHistoryMgrSOM
            ] = deps;

            return {
                listId: 'account.widgets.core',
                widgetsDefinition: () => [
                    ['ordercancellationform', OrderCancellationForm, 'ajaxform'],
                    ['orderHistoryMgr', OrderHistoryMgrSOM, 'orderHistoryMgr']
                ]
            };
        })
});
