import eventBus from 'widgets/toolbox/eventBus';
import { get } from 'widgets/toolbox/util';
import {
    trackProductClick, setTilePosition, pageProperties, report
} from '../utils/base';
import {
    getWishlistImpressions, impressionsDebounce, hasBeenImpressed, reportImpression
} from './impressions';
/**
 * @description GTM events
 * @module events
 * @category widgets
 * @subcategory gtm/components
 */
let shippingMethodName = '';

const eventActionOrderCancel = 'private_area-my_orders-cancel_item';
const eventAccount = 'private_area-product';
const eventCategory = 'loyalty';

/**
 * @description Init GTM events
 * @returns {void}
 */
export function init() {
    /**
     * @description Tracks in dataLayer an information about product added to wishlist
     * @event "wishlist.add.success"
     * @listens "wishlist.add.success"
     * @returns {void}
     */
    eventBus.on('wishlist.add.success', (data) => {
        Object.keys(data.gtmInfo).forEach((key) => {
            if (key === 'name') {
                data.gtmInfo.item_name = data.gtmInfo.name;
                delete data.gtmInfo.name;
            } else if (key === 'id') {
                data.gtmInfo.item_id = data.gtmInfo.id;
                delete data.gtmInfo.id;
            } else if (key === 'category') {
                data.gtmInfo.item_category = data.gtmInfo.category;
                delete data.gtmInfo.category;
            } else if (key === 'variant') {
                data.gtmInfo.item_variant = data.gtmInfo.variant;
                delete data.gtmInfo.variant;
            }
        });

        const event = {
            event: 'add_to_wishlist',
            ecommerce: {
                currencyCode: pageProperties.currentCurrency,
                value: data.gtmInfo.price ? data.gtmInfo.price : null,
                items: data.gtmInfo
            }
        };

        report(event);
    });
    /**
     * @description Tracks in dataLayer an information about product impressions on product tile init
     * @event "product.tile.init"
     * @listens "product.tile.init"
     * @returns {void}
     */
    eventBus.on('product.tile.init', tile => {
        const impression = tile.data('analytics');

        if (!impression) {
            return;
        }

        impression.productID = tile.data('pid');

        if (impression && !hasBeenImpressed(impression.productID || impression.id)) {
            setTilePosition(impression, impression.productID);

            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
            impressionsDebounce.push(impression);

            // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
            reportImpression();
        }
    });
    /**
     * @description Tracks in dataLayer an information about product impressions on wishlist products init
     * @event "wishlist.products.init"
     * @listens "wishlist.products.init"
     * @returns {void}
     */
    eventBus.on('wishlist.products.init', (products, step) => {
        const impressions = getWishlistImpressions(products);

        if (!impressions.length) {
            return;
        }

        const event = {
            ecommerce: {
                currencyCode: pageProperties.currentCurrency,
                impressions: impressions
            }
        };

        if (step && (step === 'browsing')) {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'event' does not exist on type '{ ecommer... Remove this comment to see the full error message
            event.event = 'productImpressions';
        }

        report(event);
    });

    /**
     * @description Tracks in dataLayer an information about Account Signup
     * @event "account.signUp"
     * @listens "account.signUp"
     * @returns {void}
     */
    eventBus.on('account.signUp', (obj) => {
        const newsletter = obj.newsletter ? 'yes' : 'no';
        const marketing = obj.marketing ? 'yes' : 'no';
        const concatValue = `${newsletter}-${marketing}`;
        const eventLabel = obj.formName ? obj.formName : '';

        report({
            event: 'signUp',
            eventCategory: eventCategory,
            eventAction: 'private_area-signUp',
            eventLabel: eventLabel,
            eventDetail: concatValue
        });
    });

    /**
     * @description Tracks in dataLayer an information about Account Login
     * @event "account.login"
     * @listens "account.login"
     * @returns {void}
     */
    eventBus.on('account.login', () => {
        report({
            event: 'login',
            eventCategory: eventCategory,
            eventAction: 'private_area-login',
            eventLabel: 'email' // login method
        });
    });

    /**
     * @description Tracks in dataLayer an information about Newsletter
     * @event "account.newsletter"
     * @listens "account.newsletter"
     * @returns {void}
     */
    eventBus.on('account.newsletter', () => {
        report({
            event: 'newsletter',
            eventCategory: eventCategory,
            eventAction: 'newsletter',
            eventLabel: 'signUp'
        });
    });

    // eslint-disable-next-line spellcheck/spell-checker
    /**
     * @description Tracks in dataLayer an information about BIS notify me
     * @event "backinstock.product"
     * @listens "backinstock.product"
     * @returns {void}
     */
    eventBus.on('backinstock.product', (obj) => {
        const productID = obj.productID ? obj.productID.getValue() : '';
        const productSize = obj.productSize ? obj.productSize.getValue() : '';

        report({
            event: 'product',
            eventCategory: eventCategory,
            eventAction: 'notify_me',
            eventLabel: productID, // product name-product id
            eventDetail: productSize // product variant-product size
        });
    });

    /**
     * @description Tracks in dataLayer an information about ShareLink Wishlist
     * @event "account.wishlist.share"
     * @listens "account.wishlist.share"
     * @returns {void}
     */
    eventBus.on('account.wishlist.share', (method) => {
        const shareMethod = method && method === 'clipboard' ? 'link' : method;

        report({
            event: eventAccount,
            eventCategory: eventCategory,
            eventAction: 'private_area-wishlist-share_list',
            eventLabel: shareMethod
        });
    });

    /**
     * @description Tracks in dataLayer an information about update Wishlist
     * @event "account.wishlist.update"
     * @listens "account.wishlist.update"
     * @returns {void}
     */
    eventBus.on('account.wishlist.update', (productID) => {
        report({
            event: eventAccount,
            eventCategory: eventCategory,
            eventAction: 'private_area-wishlist-product_update',
            eventLabel: productID
        });
    });

    /**
     * @description Tracks in dataLayer an information about Order Cancel step 1
     * @event "account.order.cancel.step1"
     * @listens "account.order.cancel.step1"
     * @returns {void}
     */
    eventBus.on('account.order.cancel.step1', (orderID) => {
        report({
            event: eventAccount,
            eventCategory: eventCategory,
            eventAction: eventActionOrderCancel,
            eventLabel: 'step1',
            eventDetail: orderID
        });
    });

    /**
     * @description Tracks in dataLayer an information about Order Cancel step 2
     * @event "account.order.cancel.step1"
     * @listens "account.order.cancel.step1"
     * @returns {void}
     */
    eventBus.on('account.order.cancel.step2', (obj) => {
        report({
            event: eventAccount,
            eventCategory: eventCategory,
            eventAction: eventActionOrderCancel,
            eventLabel: 'step2',
            eventDetail: obj && obj.orderID && obj.reason ? obj.orderID + '-' + obj.reason.getValue() : ''
        });
    });

    /**
     * @description Tracks in dataLayer an information about Order Cancel step 3
     * @event "account.order.cancel.step1"
     * @listens "account.order.cancel.step1"
     * @returns {void}
     */
    eventBus.on('account.order.cancel.step3', (obj) => {
        report({
            event: eventAccount,
            eventCategory: eventCategory,
            eventAction: eventActionOrderCancel,
            eventLabel: 'step3',
            eventDetail: obj && obj.orderID && obj.reason ? obj.orderID + '-' + obj.reason : ''
        });
    });

    /**
     * @description Tracks in dataLayer an information about Order Cancel step 1
     * @event "account.order.track"
     * @listens "account.order.track"
     * @returns {void}
     */
    eventBus.on('account.order.track', (obj) => {
        const orderID = obj.orderID ? obj.orderID.getValue() : '';

        report({
            event: eventAccount,
            eventCategory: eventCategory,
            eventAction: 'private_area-track_order',
            eventLabel: 'request_submitted',
            eventDetail: orderID
        });
    });

    /**
     * @description Tracks in dataLayer an information about quick view opening
     * @event "product.tile.qv.open"
     * @listens "product.tile.qv.open"
     * @returns {void}
     */
    eventBus.on('product.tile.qv.open', qvData => {
        report({
            event: 'pageLoad',
            ecommerce: {
                currencyCode: pageProperties.currentCurrency,
                detail: {
                    actionField: { list: 'Product quick view' },
                    products: [qvData.gtmInfo]
                }
            }
        });
    });
    /**
     * @description Tracks in dataLayer an information about quick view opening on editing product step
     * @event "product.tile.qv.open.edit"
     * @listens "product.tile.qv.open.edit"
     * @returns {void}
     */
    eventBus.on('product.tile.qv.open.edit', editEl => {
        if (!editEl) {
            return;
        }

        const productInfo = editEl.data('analytics');

        if (!productInfo) {
            return;
        }

        report({
            event: 'pageLoad',
            ecommerce: {
                currencyCode: pageProperties.currentCurrency,
                detail: {
                    actionField: { list: 'Product quick view' },
                    products: [productInfo]
                }
            }
        });
    });
    /**
     * @description Tracks in dataLayer an information about product tile link click
     * @event "tile.product.link.click"
     * @listens "tile.product.link.click"
     * @returns {void}
     */
    eventBus.on('tile.product.link.click', link => {
        trackProductClick(link);
    });
    /**
     * @description Tracks in dataLayer an information about cart product tile link click
     * @event "cart.product.link.click"
     * @listens "cart.product.link.click"
     * @returns {void}
     */
    eventBus.on('cart.product.link.click', link => {
        trackProductClick(link);
    });
    /**
     * @description Tracks in dataLayer an information about minicart product tile link click
     * @event "minicart.product.link.click"
     * @listens "minicart.product.link.click"
     * @returns {void}
     */
    eventBus.on('minicart.product.link.click', link => {
        trackProductClick(link);
    });
    /**
     * @description Tracks in dataLayer an information about product details link click
     * @event "detail.product.link.click"
     * @listens "detail.product.link.click"
     * @returns {void}
     */
    eventBus.on('detail.product.link.click', link => {
        trackProductClick(link);
    });
    /**
     * @description Tracks in dataLayer an information about order tile link click
     * @event "orderdetail.product.link.click"
     * @listens "orderdetail.product.link.click"
     * @returns {void}
     */
    eventBus.on('orderdetail.product.link.click', link => {
        trackProductClick(link);
    });
    /**
     * @description Tracks in dataLayer an information about searchbox product tile link click
     * @event "searchbox.product.link.click"
     * @listens "searchbox.product.link.click"
     * @returns {void}
     */
    eventBus.on('searchbox.product.link.click', link => {
        trackProductClick(link);
    });
    /**
     * @description Tracks in dataLayer an information about wishlist product tile link click
     * @event "wishlist.product.link.click"
     * @listens "wishlist.product.link.click"
     * @returns {void}
     */
    eventBus.on('wishlist.product.link.click', link => {
        trackProductClick(link);
    });
    /**
     * @description Tracks in dataLayer an information about adding products to cart
     * @event "product.added.to.cart"
     * @listens "product.added.to.cart"
     * @returns {void}
     */
    eventBus.on('product.added.to.cart', (cart, widget) => {
        const tileInfo = widget.currentGtmInfo || widget.data('analytics');

        if (!tileInfo) {
            return;
        }

        if (!tileInfo.quantity) {
            tileInfo.quantity = 1;
        }

        report({
            event: 'addToCart',
            ecommerce: {
                currencyCode: pageProperties.currentCurrency,
                add: {
                    products: [].concat(tileInfo)
                }
            }
        });
    });
    /**
     * @description Tracks in dataLayer an information about updates on cart
     * @event "cart.updated"
     * @listens "cart.updated"
     * @returns {void}
     */
    eventBus.on('cart.updated', cart => {
        if (cart && cart.items) {
            cart.items.forEach(item => {
                if (item.gtmInfo) {
                    item.gtmInfoStr = JSON.stringify(item.gtmInfo);
                }
            });
        }
    });
    /**
     * @description Tracks in dataLayer an information about removing products from cart
     * @event "cart.remove.product"
     * @listens "cart.remove.product"
     * @returns {void}
     */
    eventBus.on('cart.remove.product', cart => {
        if (!cart.removeButton) {
            return;
        }

        const tileInfo = cart.removeButton.data('analytics');

        if (!tileInfo) {
            return;
        }

        tileInfo.quantity = parseInt(cart.removeButton.data('quantity'), 10) || 1;
        delete tileInfo.list;
        report({
            event: 'removeFromCart',
            ecommerce: {
                currencyCode: pageProperties.currentCurrency,
                remove: {
                    products: [tileInfo]
                }
            }
        });
    });
    /**
     * @description Tracks in dataLayer an information about removing products from minicart
     * @event "minicart.remove.product"
     * @listens "minicart.remove.product"
     * @returns {void}
     */
    eventBus.on('minicart.remove.product', minicart => {
        if (!minicart.removeProductLink) {
            return;
        }

        const tileInfo = minicart.removeProductLink.data('analytics');

        if (!tileInfo) {
            return;
        }

        tileInfo.quantity = parseInt(minicart.removeProductLink.data('quantity'), 10) || 1;
        report({
            event: 'removeFromCart',
            ecommerce: {
                currencyCode: pageProperties.currentCurrency,
                remove: {
                    products: [tileInfo]
                }
            }
        });
    });
    /**
     * @description Tracks in dataLayer an information about customer's redirect to checkout
     * @event "cart.page.submitted"
     * @listens "cart.page.submitted"
     * @returns {void}
     */
    eventBus.on('cart.page.submitted', cart => {
        const products = [];
        const items = cart.cart.items;

        if (items && items.length) {
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
            items.forEach(item => products.push(item.gtmInfo));
        }

        report({
            event: 'checkout',
            ecommerce: {
                currencyCode: pageProperties.currentCurrency,
                checkout: {
                    actionField: {
                        step: 1
                    },
                    products
                }
            }
        });
    });
    /**
     * @description Tracks in dataLayer an information about selected shipping method
     * @event "cart.update.shipping.method"
     * @listens "cart.update.shipping.method"
     * @returns {void}
     */
    eventBus.on('cart.update.shipping.method', newShippingMethodName => {
        if (shippingMethodName === newShippingMethodName) {
            return;
        }

        shippingMethodName = newShippingMethodName;
        report({
            event: 'shippingSelected',
            ecommerce: {
                checkout_option: {
                    actionField: { step: 2, option: shippingMethodName }
                }
            }
        });
    });
    /**
     * @description Tracks in dataLayer an information about customer's filling a shipping step
     * @event "checkout.shipping.step.submitted"
     * @listens "checkout.shipping.step.submitted"
     * @returns {void}
     */
    eventBus.on('checkout.shipping.step.submitted', checkoutMgr => {
        if (checkoutMgr.order && checkoutMgr.order.gtmInfo) {
            shippingMethodName = get(checkoutMgr, 'order.shipping.0.selectedShippingMethod.displayName');

            if (!shippingMethodName) {
                const applicableShippingMethods = get(checkoutMgr, 'order.shipping.0.applicableShippingMethods') || [];

                applicableShippingMethods.forEach(applicableShippingMethod => {
                    if (applicableShippingMethod && applicableShippingMethod.selected) {
                        shippingMethodName = applicableShippingMethod.displayName;
                    }
                });
            }

            report({
                event: 'checkout',
                ecommerce: {
                    currencyCode: pageProperties.currentCurrency,
                    checkout: {
                        actionField: {
                            step: 2,
                            option: shippingMethodName
                        },
                        products: checkoutMgr.order.gtmInfo.products
                    }
                }
            });
        }
    });
    /**
     * @description Tracks in dataLayer an information about changed payment method
     * @event "checkout.paymentmethod.changed"
     * @listens "checkout.paymentmethod.changed"
     * @returns {void}
     */
    eventBus.on('checkout.paymentmethod.changed', (paymentMethodName) => {
        if (!paymentMethodName) {
            return;
        }

        report({
            event: 'paymentSelected',
            ecommerce: {
                checkout_option: {
                    actionField: {
                        step: 3,
                        option: paymentMethodName.name
                    }
                }
            }
        });
    });
    /**
     * @description Tracks in dataLayer an information about customer's filling a billing step
     * @event "checkout.billing.step.submitted"
     * @listens "checkout.billing.step.submitted"
     * @returns {void}
     */
    eventBus.on('checkout.billing.step.submitted', checkoutMgr => {
        if (checkoutMgr.order && checkoutMgr.order.gtmInfo) {
            let paymentMethod = get(checkoutMgr, 'order.billing.payment.selectedPaymentInstruments.0.name');

            if (!paymentMethod) {
                paymentMethod = get(checkoutMgr, 'order.billing.payment.applicablePaymentMethods.0.name') || '';
            }

            report({
                event: 'checkout',
                ecommerce: {
                    currencyCode: pageProperties.currentCurrency,
                    checkout: {
                        actionField: {
                            step: 3,
                            option: paymentMethod
                        },
                        products: checkoutMgr.order.gtmInfo.products
                    }
                }
            });
        }
    });
    /**
     * @description Tracks in dataLayer an information about customer's submitting summary step
     * @event "checkout.summary.step.submitted"
     * @listens "checkout.summary.step.submitted"
     * @returns {void}
     */
    eventBus.on('checkout.summary.step.submitted', checkoutMgr => {
        if (checkoutMgr.order && checkoutMgr.order.gtmInfo) {
            report({
                event: 'checkout',
                ecommerce: {
                    currencyCode: pageProperties.currentCurrency,
                    checkout: {
                        actionField: {
                            step: 4
                        },
                        products: checkoutMgr.order.gtmInfo.products
                    }
                }
            });
        }
    });
}
